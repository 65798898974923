import React, { useState } from 'react';
import { useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import { defaultLocale } from './Locale';
import Core from './Core';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import '../styles/quiz.css'

const Quiz = ({ quiz, showDefaultResult, onComplete, customResultPage, showInstantFeedback, continueTillCorrect, uuid, startQuestionIndex}) => {

  // Constants for start and then refresh
  const [start, setStart] = useState(false)
  const [questions, setQuestions] = useState(quiz.questions)

  const history = useHistory();

  const appLocale = {
    ...defaultLocale,
    ...quiz.appLocale
  };


  const quizReady = () => {
    return (
      <Card  className="text-center shadow p-3 mb-5 bg-white rounded">
        <Card.Header>Quiz Ready</Card.Header>
        <Card.Body>
          <Card.Subtitle className="mb-2 text-muted">{quiz.questions.length} Questions</Card.Subtitle>
          <Card.Text  className="text-center" > Best of Luck!!</Card.Text>
          <Button id = "startQuizBtn" className="startQuizBtn btn mx-auto" size="lg" onClick={() => setStart(true)} >{appLocale.startQuizBtn}</Button>
        </Card.Body>
      </Card>
    )
  }


  return (
    <div className="react-quiz-container">
      <Row>
        <Col lg={4}></Col>
        <Col lg={4}>
          {!start && quizReady()}
        </Col>
        <Col lg={4}></Col>
      </Row>
      {start && <Core
        questions={questions}
        showDefaultResult={showDefaultResult}
        onComplete={onComplete}
        customResultPage={customResultPage}
        showInstantFeedback={showInstantFeedback}
        continueTillCorrect={continueTillCorrect}
        appLocale={appLocale}
        uuid={uuid}
        startQuestionIndex={startQuestionIndex}
      />
      }
    </div>
  );


  Quiz.propTypes = {
    quiz: PropTypes.object,
    shuffle: PropTypes.bool,
    showDefaultResult: PropTypes.bool,
    onComplete: PropTypes.func,
    customResultPage: PropTypes.func,
    showInstantFeedback: PropTypes.bool,
    continueTillCorrect: PropTypes.bool
  };

}

export default Quiz;