import React from 'react';
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import {AmplifyS3Image} from "@aws-amplify/ui-react";

import '../styles/previewQuestion.css'

const PreviewQuestion = (question, services, options, explanation, imageUrl, buttonBar, platform, level, peerReiew) => {

  const renderOptions = (options) => {
    return (
      options.map((option, i) => (

        <div onClick={null} key={i} className="checkbox">
          <label>
            <input type="checkbox" autoComplete='off' defaultChecked={false} id={i} value={option} />
            {
              " " + option["option"]
            }
          </label>
        </div>
      ))
    );
  }

  const renderServices = (services) => {
    return (
      services.map((service, i) => (
          <li>
            {service["label"]}
          </li>
      ))
    );
  }

  // render answer 
  const renderAnswerInResult = (options) => {
    let answers = [], i
    for (i = 0; i < options.length; i++) {
      if (options[i]["check"]) {
        answers.push(options[i])
      }
    }
    return (
      answers.map(answer => (
        <li>{answer.option}</li>
      ))
    );
  };


  return (
    <div className="preview shadow-sm p-3 mb-5 bg-white rounded" key={1}>
      <Card className="text-cent">
        <Card.Header>Preview Question</Card.Header>
        <Card.Body >
          <Card.Title className="text-left" id="question">Question</Card.Title>
          <Card.Text className="question-question">
            {question}
          </Card.Text>
          {!peerReiew && imageUrl.length > 1 && <Image src={imageUrl} fluid/>
          }
          {
            peerReiew && imageUrl.length > 1 && <AmplifyS3Image imgKey={imageUrl}></AmplifyS3Image>
          }
          <Card.Text>
            <div className="result-answer-options">
              {
                renderOptions(options)
              }
            </div>
            <div className="result-answer-header">
              Answer(s)
              </div> 
            <div className="result-answer">
              {renderAnswerInResult(options)}
            </div>
            <div className="result-platform-header">
              Platform
              </div>
            <div className="result-platform">
              {platform}
            </div>
            <div className="result-level-header">
            Difficulty Level
            </div>
            <div className="result-level">
              {level}
            </div>
            <div className="result-services-header">
              Sub Topics
            </div>
            <div className="result-services">
              {renderServices(services)}
            </div>
            <div className="result-services-header">
              Explaination
            </div>
            <div className="result-services">
            {explanation}
              </div>
          </Card.Text>
          {buttonBar}
        </Card.Body>
      </Card>
    </div>
  )

}

export default PreviewQuestion;