import React from 'react';
import CookieConsent from "react-cookie-consent";
import HomeSigned from './HomeSignedIn'
import Home from './HomePage'
import QuizFrame from './QuizFrame'
import { Link } from 'react-router-dom';
import ResultsFrame from './ResultsFrame'
import SubmitFrame from './SubmitFrame'
import PrivatePolicy from './PrivatePolicy'
import TermsAndConditions from './TermsAndConditions'
import './styles/App.css';
import Navtop from './NavBar'
import Footer from './Footer'
import TrackSubQuestion from './TrackSubQuestion'
import PeerReview from './components/PeerReview'
import Signup from './Signup'
import Amplify from 'aws-amplify';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import awsconfig from './aws-exports';
import ProfilePage from './components/ProfilePage';
import RouteChangeTracker from './components/RouteChangeTracker';
import AuthAmp from './AuthAmp'
import { Auth, Hub } from 'aws-amplify';
import ReactGA from 'react-ga';
import FAQ from './Faq'
import Contact from './Contact'
Amplify.configure(awsconfig);

const TRACKING_ID = "UA-202447296-1"; // YOUR_OWN_TRACKING_ID

ReactGA.initialize(TRACKING_ID);
function App() {

  const [user, updateUser] = React.useState(null);
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => updateUser(user))
      .catch(() => console.log('No signed in user.'));
    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          return updateUser(data.payload.data);
        case 'signOut':
          return updateUser(null);
      }
    });
  }, [])

  return (
    <Router>
      <Navtop />
      <div className="App">
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/Faq' exact component={FAQ} />
          <Route path='/Contact' exact component={Contact}/> 
          <Route path='/PrivatePolicy' exact component={PrivatePolicy}/> 
          <Route path='/TermsAndConditions' exact component={TermsAndConditions}/> 
          <Route path='/Signup' exact component={Signup}/> 
          {!user && <AuthAmp></AuthAmp>}
          <Route path='/Userhome' exact component={HomeSigned} />
          <Route path='/Profile' exact component={ProfilePage} />
          <Route path='/Quiz' exact component={QuizFrame} />
          <Route path='/Results' exact component={ResultsFrame} />
          <Route path='/SubmitQuestion' exact component={SubmitFrame} />
          <Route path='/PeerReview' exact component={PeerReview} />
          <Route path='/TrackSubQuestion' exact component={TrackSubQuestion} />
          <Redirect to='/Userhome' />
        </Switch>
        <RouteChangeTracker />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Sure man!!"
        cookieName="TheQuestionBankCookie"
        style={{ background: "#00334E" }}
        buttonStyle={{ background:"#59B08E" , color: "white", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "10px" }}><Link className="text-center" to="/PrivatePolicy">Private Policy</Link></span>
      </CookieConsent>
      <Footer />
    </Router>
  );
}

export default App;
