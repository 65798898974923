import React, { useState, useEffect } from 'react';
import Amplify, { API, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import Profile from './components/Profile'
import PastQuizzes from './components/PastQuizzes'
import Quiz from './quiz/Quiz'
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import './styles/homeSignedIn.css'
import startQuiz from './img/start-quiz.png'
import subQ from './img/sub-ques.png'
import track from './img/track.png'
import peer from './img/peer.png'
import forum from './img/forum.png'


const forumURL = "https://discourse.thequestionbank.io/"

Amplify.configure(awsconfig);


const HomeSignedIn = () => {

    const [start] = useState(false);
    const [isForum, setIsForum] = useState(false)


    const [previousQuizData, setPreviousQuizData] = useState({
        prevQuizzesQuiz: {},
        start: false
    });

    // Handleschnage is state by past quizzes to retake a quiz
    function handleChange(prevQuizzesQuiz, uuid, startQuestionIndex) {
        setPreviousQuizData({
            prevQuizzesQuiz: prevQuizzesQuiz,
            start: true,
            uuid: uuid,
            startQuestionIndex: startQuestionIndex
        });
    }

    const loadForum = () => {
        setIsForum(true)
        window.open(forumURL)
        setIsForum(false)
    }

    return (
        <div className="container-fluid fill">
            {!start && !previousQuizData.start &&
                <div className="HomeSignedIn">
                    <Row>
                        <div className="quesComand">
                            <h2> Question Comand Center</h2>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={5}>
                            <Row>
                                <Profile />
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col lg={8}>

                                    <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                        <Card.Body>
                                            <Card.Img className="card-img-top mx-auto" src={forum} alt="Card image cap" />
                                            <Card.Title>Forum</Card.Title>
                                            <Card.Text>
                                                Lets go to the discourse forum for all things The QuestionBank
                                            </Card.Text>
                                            <Button className="btn mx-auto" onClick={loadForum} size="lg">{!isForum && <>Forum</>}{isForum && <Spinner animation="border" />}</Button>
                                        </Card.Body>
                                    </Card>

                                </Col>
                                <Col></Col>
                            </Row>
                        </Col>
                        <Col lg={7}>
                            <div className="coreCards">
                                <Row>
                                    <Col>
                                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                            <Card.Body>
                                                <Card.Img className="card-img-top mx-auto" src={startQuiz} alt="Card image cap" />
                                                <Card.Title>New Quiz</Card.Title>
                                                <Card.Text>Lets start a new quiz</Card.Text>
                                                <Link to="/Quiz">
                                                    <Button id="start" className="startBtn mx-auto" size="lg">Start Quiz</Button>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                            <Card.Body>
                                                <Card.Img className="card-img-top mx-auto" src={subQ} alt="Card image cap" />
                                                <Card.Title>Submit A Question</Card.Title>
                                                <Card.Text>Lets add a question to The Question Bank</Card.Text>
                                                <Link to="/SubmitQuestion">
                                                    <Button className="btn mx-auto" size="lg">Submit  Question</Button>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                            <Card.Img className="card-img-top mx-auto" src={track} alt="Card image cap" />
                                            <Card.Body>
                                                <Card.Title>Track Submitted Question</Card.Title>
                                                <Card.Text>See the progress of a submitted question</Card.Text>
                                                <Link className="nav-link" to="/TrackSubQuestion">
                                                    <Button className="btn mx-auto" size="lg">Track</Button>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                            <Card.Img className="card-img-top mx-auto" src={peer} alt="Card image cap" />
                                            <Card.Body>
                                                <Card.Title>Peer Review</Card.Title>
                                                <Card.Text>Peer review other users submitted Questions</Card.Text>
                                                <Link to="/PeerReview">
                                                    <Button className="btn mx-auto" size="lg">Peer Review</Button>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PastQuizzes onChange={handleChange} />
                        </Col>
                    </Row>
                </div>
            }
            <div>
                <div>
                    {previousQuizData.start &&
                        <Quiz quiz={previousQuizData.prevQuizzesQuiz} uuid={previousQuizData.uuid} startQuestionIndex={previousQuizData.startQuestionIndex} />
                    }
                </div>
            </div >
        </div>

    );
}

export default HomeSignedIn;
