import React, { Component} from 'react';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { defaultLocale } from '../quiz/Locale';
import ReviewQuizResults from './ReviewQuizResults';
import { v4 as uuidv4 } from 'uuid';
import '../styles/pastQuizzes.css'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'

Amplify.configure(awsconfig);


class PastQuizzes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pastQuizzesArray: []
            , pastQuizQuestions: []
            , pastStartTime: null
            , pastScore: ''
            , review: false
            , arrayData: []
            , loading: false
            , sub: ""
            , token: ""
        };

        this.renderPastQuiz = this.renderPastQuiz.bind(this);
    }

    reviewPastQuiz(quizDate, uuid, quizScore) {
        //set loading 
        this.setState({ loading: true })
        Auth.currentAuthenticatedUser().then(response => {
            let pastStartTime = new Date(quizDate), pastScore = quizScore, pastQuizQuestions = [], review = false;
            // set api call 
            const apiName = 'reviewPastQuiz';
            const path = '/reviewPastQuiz';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': response.signInUserSession.idToken.jwtToken
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    sub: this.state.sub,
                    uuid: uuid
                },
            };

            //make api call 
            API
                .get(apiName, path, myInit)
                .then(response => {
                    review = true
                    let loading = false
                    pastQuizQuestions = response.data.questions
                    this.setState({
                        pastQuizQuestions, pastStartTime, pastScore, review, loading
                    })
                });
        }).catch(error => {
            console.log(error);
        })
    }

    retakePastQuiz(quiz, retake) {

        Auth.currentAuthenticatedUser().then(response => {
            let uuid, startQuestionIndex, apiName, path, myInit
            // if retaking need a new uuid and start from 1st question and call retake APi which resets all anwsers
            // if resumming then same uuid and start from last question when save was clicked and use resume API which keeps anwswers
            if (retake) {
                apiName = 'quizRetake';
                path = '/quizRetake';
                myInit = { // OPTIONAL
                    headers: {
                        'Authorization': response.signInUserSession.idToken.jwtToken
                    }, // OPTIONAL
                    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                    queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                        sub: this.state.sub,
                        uuid: quiz.uuid
                    },
                };
                uuid = uuidv4()
                startQuestionIndex = 0
            } else {
                apiName = 'resumeQuiz';
                path = '/resumeQuiz';
                myInit = { // OPTIONAL
                    headers: {
                        'Authorization': response.signInUserSession.idToken.jwtToken
                    }, // OPTIONAL
                    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                    queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                        sub: this.state.sub,
                        uuid: quiz.uuid
                    },
                };
                uuid = quiz.uuid
                startQuestionIndex = parseInt(quiz.complete)
            }

            //make api call 
            API
                .get(apiName, path, myInit)
                .then(response => {
                    let quiz = response.data
                    this.props.onChange(quiz, uuid, startQuestionIndex)
                });
        }).catch(error => {
            console.log(error)
        })
    }


    componentDidMount() {

        //Get sub and set state 
        Auth.currentAuthenticatedUser().then(response => {
            this.setState({ token: response.signInUserSession.idToken.jwtToken });
            this.setState({ sub: response.attributes.sub });
            // set api call 
            const apiName = 'pastQuizzes';
            const path = '/pastQuizzes';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': response.signInUserSession.idToken.jwtToken
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    sub: response.attributes.sub
                },
            };
            //make api call 
            API
                .get(apiName, path, myInit)
                .then(response => {
                    this.setState({
                        pastQuizzesArray: response.data
                    })
                    this.renderPastQuizNew(response.data);
                });
        });
    }


    buttonRendering(quiz) {
        if (quiz["complete"] === "true") {
            return <button className="btn" onClick={() => this.retakePastQuiz(quiz, true)}>Retake Quiz</button>
        } else {
            return <button className="btn" onClick={() => this.retakePastQuiz(quiz, false)}>Resume Quiz</button>
        }


    }

    renderPastQuiz(quiz, index) {
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{quiz.score}</td>
                <td>{quiz.uuid}</td>
                <td><button onClick={() => this.reviewPastQuiz(quiz.quizDate, quiz.uuid, quiz.score)} className="btn">Review Quiz</button></td>
                <td>{this.buttonRendering(quiz)}</td>
            </tr>
        )
    }

    renderPastQuizNew(pastQuizzesArray) {
        let i, arrayData = []
        for (i = 0; i < pastQuizzesArray.length; i++) {
            arrayData.push({
                 date: pastQuizzesArray[i]["quizDate"]
                , uuid: pastQuizzesArray[i]["uuid"]
                , score: pastQuizzesArray[i]["score"]
                , review: pastQuizzesArray[i]  //<button onClick={() => this.reviewPastQuiz(pastQuizzesArray[i]["quizDate"], pastQuizzesArray[i]["uuid"],pastQuizzesArray[i]["score"]).bind(this)} className="btn">Review Quiz</button>
                , retake: pastQuizzesArray[i] ///this.buttonRendering(pastQuizzesArray[i], pastQuizzesArray[i]["compelte"])
            }
            )
        }
        this.setState({
            arrayData: arrayData
        })
    }

    render() {

        const appLocale = {
            ...defaultLocale,
            //...quiz.appLocale
        };


        const products = this.state.arrayData
        /*[
            { uuid: 1, review: 'George', retake: 'Monkey' },
            { uuid: 2, review: 'Jeffrey', retake: 'Giraffe' },
            { uuid: 3, review: 'Alice', retake: 'Giraffe' },
            { uuid: 4, review: 'Foster', retake: 'Tiger' },
            { uuid: 5, review: 'Tracy', retake: 'Bear' },
            { uuid: 6, review: 'Joesph', retake: 'Lion' },
            { uuid: 7, review: 'Tania', retake: 'Deer' },
            { uuid: 8, review: 'Chelsea', retake: 'Tiger' },
            { uuid: 9, review: 'Benedict', retake: 'Tiger' },
            { uuid: 10, review: 'Chadd', retake: 'Lion' },
            { uuid: 11, review: 'Delphine', retake: 'Deer' },
            { uuid: 12, review: 'Elinore', retake: 'Bear' },
            { uuid: 13, review: 'Stokes', retake: 'Tiger' },
            { uuid: 14, review: 'Tamara', retake: 'Lion' },
            { uuid: 15, review: 'Zackery', retake: 'Bear' }
          ];*/

        const columns = [
            {
                dataField: 'date'
                ,text: 'Date'
                ,sort: true
            },
            {
                dataField: 'uuid'
                , text: 'ID'
            },
            {
                dataField: 'score'
                , text: 'Score'
            },
            {
                dataField: 'review'
                , formatter: (cell, row) => {
                    return <button onClick={() => this.reviewPastQuiz(row.date, row.uuid, row.score)} className="btn">Review Quiz</button>
                },
                text: 'Review'
            },
            {
                dataField: 'retake',
                formatter: (cell) => {
                    return this.buttonRendering(cell)
                },
                text: 'Retake'
            }
        ];

        const defaultSorted = [{
            dataField: 'date',
            order: 'desc'
          }];
          
        const pagination = paginationFactory({
            page: 1,
            sizePerPage: 10,
            lastPageText: '>>',
            firstPageText: '<<',
            nextPageText: '>',
            prePageText: '<',
            showTotal: true,
            alwaysShowAllBtns: true,
            onPageChange: function (page, sizePerPage) {
              
            },
            onSizePerPageChange: function (page, sizePerPage) {
             
            }
        });

        const loading = () => {
            return (
                <Card className="text-center shadow p-3 mb-5 bg-white rounded mx-auto">
                    <Card.Header>Loading Review</Card.Header>
                    <Card.Body>
                        <Card.Title>Fetching your quiz results as we speak</Card.Title>
                        <div className="spinner">
                            <Spinner animation="border" />
                        </div>
                    </Card.Body>
                </Card>
            )
        }

        const closeBtn = () => {
            return (
                <Button id="closeReview" onClick={() => this.setState({ review: false })} size="lg" className="closeReview mx-auto">Close Review</Button>
            )
        }

        return (
            <div className="pastQuizes">
                {!this.state.review && !this.state.loading &&
                    <div className="pastQuizzes mx-auto">
                        <Row>
                            <Col>
                                <div className="header">
                                    <h3> Review & Retake Past Quizzes</h3>
                                </div>
                                <div className="pastQuizzesTable">
                                    <BootstrapTable bootstrap4 keyField='uuid' data={products} columns={columns} pagination={pagination} bordered={false} defaultSorted = {defaultSorted} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
                {  this.state.loading &&
                    <div className="mx-auto">
                        <Row>{loading()}</Row>
                    </div>
                }
                {this.state.review && !this.state.loading &&
                    <div className="mx-auto">
                        <Row>
                            <ReviewQuizResults questions={this.state.pastQuizQuestions} startTime={this.state.pastStartTime} appLocale={appLocale} score={this.state.pastScore} closeBtn={closeBtn} /></Row>
                    </div>

                }
            </div>

        )
    }

}

export default PastQuizzes;
