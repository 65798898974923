import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import ReviewQuizResults from '../components/ReviewQuizResults'
import Results from '../components/Results'
import SaveQuiz from '../components/SaveQuiz'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import '../styles/core.css'


const Core = ({ questions, appLocale, customResultPage, uuid, startQuestionIndex }) => {


    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(startQuestionIndex);
    const [question, setQuestion] = useState(questions[currentQuestionIndex]);
    const [userAnswer, setUserAnswer] = useState([]);
    const [endQuiz, setEndQuiz] = useState(false);
    const [filteredValue, setFilteredValue] = useState('all');
    const [showDefaultResultState, setShowDefaultResult] = useState(true);
    const [score, setScore] = useState(0);
    const [startTime, setstartTime] = useState(new Date());
    const [exit, setExit] = useState(false);
    const [review, setReview] = useState(false)



    useEffect(() => {
        setQuestion(questions[currentQuestionIndex]);
    });

    useEffect(() => {
        setUserAnswer(questions[currentQuestionIndex].userAnswer);
    }, [currentQuestionIndex]);

    useEffect(() => {
        setQuestion(questions[currentQuestionIndex]);
    }, [currentQuestionIndex]);


    /**
     * Calculate Result 
     * */
    const calculateScore = (questions) => {
        let i;
        let resultScore = 0
        for (i = 0; i < questions.length; i++) {
            // looking of the true values   
            let predicate = true;
            const acceptedValues = [predicate]

            //filter out to get the user answers which have been set to true 
            var filteredObject = Object.keys(questions[i].userAnswer).reduce(function (r, e) {
                if (acceptedValues.includes(questions[i].userAnswer[e])) r[e] = questions[i].userAnswer[e]
                return r;
            }, {})

            //get the kets ready for a compare
            var userAnsKeys = Object.keys(filteredObject).sort();
            var ansKeys = Object.keys(questions[i].answer).sort();

            //compare the keys and set the score 
            if (JSON.stringify(userAnsKeys) === JSON.stringify(ansKeys)) {
                resultScore++
                questions[i].questionResult = "correct"
            } else {
                questions[i].questionResult = "incorrect"
            }
        }
        setScore(resultScore)
    }



    /**save the answers
    //one to the question index  
    //set the image if there is one
    // last question called submit method which will calculate score and move pages by not having the button appear?? 
    **/
    const nextQuestion = (currentQuestionIndex) => {
        //set index plus one if not the last question else end 
        if (currentQuestionIndex + 1 < questions.length) {
            //set in index plus one 
            //setUserAnswer(userAnswer); 
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            //make photo call 
        } else {
            calculateScore(questions)
            setEndQuiz(true)
        }

    }


    //minus one to question index 
    //set image if there is one 
    // update with previous answers? 
    const previousQuestion = (currentQuestionIndex) => {

        //set index minus one if not the first question 
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1)
        }

    };

    //onclick to set answers
    // need to stop the same answer being slected twice by going backwards
    const handleOptionOnclick = (e) => {
        userAnswer[e.target.value] = e.target.checked
        setUserAnswer(userAnswer)
    };

    const handleResultsSave = (saved) => {
        setReview(saved);
    }

    const closeBtn = () => {
        return (
            <Link to="/Userhome">
                <Button className="submitBtn" size="lg">Exit Results</Button>
            </Link>
        )
    }

    //render out method which is the core quiz page
    //and then goes to the results page one submit is pressed
    return (
        <div className="core">
            {
                exit && <SaveQuiz questions={questions} startTime={startTime} uuid={uuid} currentQuestionIndex={currentQuestionIndex} />
            }
            {!endQuiz && !exit &&
                <div className="questionWrapperBody">
                    <Row>
                        <Col lg={2} sm={0}></Col>
                        <Col lg={8} className="questionWrapperBody-questions">
                            <Row><div className="question-number"><h3>{currentQuestionIndex + 1} of {questions.length}</h3></div></Row>
                            <Row><div className="question-title"><h3>Question</h3></div></Row>
                            <Row><div className="question-question"><h5>{question.question}</h5></div></Row>
                            <Row className="justify-content-center">{question.imageURL.length > 1 && <AmplifyS3Image theme={{ photoImg: { maxWidth: '700px', maxHeight: '700px' } }} imgKey={question.imageURL} />}</Row>
                            <div className="option-group">
                                {
                                    Object.keys(question.options).map((key, i) => (
                                        <Row>
                                            <div key={question.questionId + '-' + i} className="option-checkbox">
                                                <label>
                                                    <input type="checkbox" autoComplete='off' defaultChecked={userAnswer[key]} id={question.questionId + '-' + key} value={key} onChange={handleOptionOnclick} />
                                                    {
                                                        "   " + question.options[key]
                                                    }
                                                </label>
                                            </div>
                                        </Row>
                                    ))
                                }
                            </div>
                            <Row className="buttonBarRow">
                                <div className="btnBar d-flex justify-content-between">
                                    <div className="exit"><Button className="exitBtn" size="lg" onClick={() => setExit(true)}>Exit</Button></div>
                                    <div className="prev-next">
                                        <ButtonToolbar className="justify-content-between">
                                            <ButtonGroup aria-label="First group">
                                            <Button className="previousBtn" size="lg" onClick={() => previousQuestion(currentQuestionIndex)} >Previous</Button>
                                            </ButtonGroup>
                                            <ButtonGroup aria-label="Second group">
                                            <Button className="submitBtn" size="lg" onClick={() => nextQuestion(currentQuestionIndex)}>next</Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </div>
                                </div>

                            </Row>
                        </Col>
                        <Col lg={2} sm={0}></Col>
                    </Row>
                </div>
            }
            {endQuiz && showDefaultResultState && customResultPage === undefined && !review &&
                <div className="questionWrapperBody-questions">
                    <Results questions={questions} score={score} totalQuestions={questions.length} startTime={startTime} uuid={uuid} review={handleResultsSave} />
                </div>}
            {endQuiz && showDefaultResultState && customResultPage === undefined && review &&
                <div>
                    <ReviewQuizResults questions={questions} startTime={startTime} appLocale={appLocale} score={score} closeBtn={closeBtn} />
                </div>
            }
        </div>

    );


};

export default withRouter(Core);