import React from 'react';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'

import './styles/faqs.css'

const FAQ = () => {


    return (
        <div className="container faq mx-auto">
            <div className="faqheader-top">
                    <Row>
                        <Col>
                            <h2>Frequently Asked Questions</h2>
                        </Col>
                    </Row>
                </div>
                <div className="faqheader-mid">
                    <Row>
                        <Col>
                            <p>
                               Below is some of our Frequently Asked Questions. 
                        </p>
                        </Col>
                    </Row>
            </div>
            <div className="faqcards mx-auto">
                <Row>
                    <Col>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                        What is The QuestionBank?
      </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Card.Text>
                                        The QuestionBank is built with the aim of it becoming the biggest community quiz resource on the web. The idea behind the QuestionBank is that users can submit questions, which are then peer-reviewed by other users. Once peer reviewed a couple of Machine Learning algos are run over the questions, before a final admin user approves the question bfore entering the bank. This way we have an ever-growing up-to-date bank of questions to create quizzes out of.
                                        </Card.Text>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                                        How do I access The QuestionBank?
      </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <Card.Text>
                                        The question bank requires a user to sign up and sign in by <a href="https://www.thequestionbank.io/Userhome">clicking here</a>. The sign up and in process is handled by AWS cognitio. In fact the whole website is built on AWS.
                                        </Card.Text>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                                        How do I submit a question?
      </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                    <Card.Text>After you have <a href="https://www.thequestionbank.io/Userhome">signed in</a> the <a href="https://www.thequestionbank.io/SubmitQuestion">Submit Question</a> page takes you through the wizard where a question can be submitted. 
                                    </Card.Text>
                                    </Card.Body> 
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                                        What are the steps for a question to be accepted?
      </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                    <Card.Text>
                                        Once a question has been submitted it enters The QuestionBank's peer review process. The question is currently put out to all users of the question bank. 
                                        These users are able to skip, accept or reject a question on the <a href="https://www.thequestionbank.io/PeerReview">Peer Review</a> section of the app. 
                                        Once the question has been review by a minium of 10 other users it will moved onto the Admin review stage if it has had greater than 60% acceptance. 
                                        One of The QuestionBank's Admins will then make any final adjustments to the question and ensure it is not a duplicate of an exisiting question before adding it to The QuestionBank. 
                                    </Card.Text>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">
                                        What is Peer review?
      </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <Card.Text>
                                        <a href="https://www.thequestionbank.io/PeerReview">Peer Review</a> is a process by which other members of The QuestionBank review user submitted questions. 
                                        Peer Review is intended to act as a check so only the best questions enter the quiz process. Peer Review is also used to review older questions which are in the bank to ensure they are still relevant.
                                        </Card.Text>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="5">
                                        What Topics are available?
      </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body> 
                                        <Card.Text>
                                            Presently we are starting with AWS Questions. However, we will be expanding this to other ICT qualifications and then into the realms of anything and everything.
                                        </Card.Text>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>

            </div>
        </div>

    )

}

export default FAQ