import React from 'react'
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


import './styles/footer.css'
function Footer() {
  return (
    <footer className="footer">
      <div className='container-fuild padding'>
        <Row className="text-center">
          <Col className="text-center">
            <Link className="text-center" to="/">Home</Link> <br />
            <Link className="text-center" to="/Contact">Contact Form</Link> <br />
            <a href="https://www.youtube.com/channel/UC1IDyL-bHNBW9fosTXHOYLw/" className="footer-li" target="_blank">YouTube </a> <a href="https://www.youtube.com/channel/UC1IDyL-bHNBW9fosTXHOYLw/" className="fa fa-youtube" target="_blank"></a><br />
          </Col>
          <Col className="text-center">
            <Link className="text-center" to="/PrivatePolicy">Private Policy</Link><br />
            <Link className="text-center" to="/TermsAndConditions">T&C's</Link><br />
            <Link className="text-center" to="/Faq">FAQ's</Link> <br />
          </Col>
        </Row>
        <Row className="text-center">
          <Col className="text-center">
            <br /><h5>www.TheQuestionBank.io</h5>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
export default Footer;