/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:6b00c41c-f200-4ade-b574-613eae81d847",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_zl1Vz8Xl0",
    "aws_user_pools_web_client_id": "gv8710uc8hh2td1rk0bndj5v5",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "getQuestions",
            "endpoint": "https://ak3tpdsqoa.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "GetProfile",
            "endpoint": "https://o2dujeqdf3.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "quizResultsDynamo",
            "endpoint": "https://pjnkvt9bp4.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "pastQuizzes",
            "endpoint": "https://rnyo2a8ao4.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "submitQuestion",
            "endpoint": "https://otkinyz4f2.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "awsServiceList",
            "endpoint": "https://91rsuxeuhh.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "reviewPastQuiz",
            "endpoint": "https://01z7y9kog7.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "quizRetake",
            "endpoint": "https://k0qxybmkzg.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "resumeQuiz",
            "endpoint": "https://vud51f11a4.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "getPeerReview",
            "endpoint": "https://ljun17cl4c.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "peerReviewPut",
            "endpoint": "https://0mr48kwqaf.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "trackSubQues",
            "endpoint": "https://7hn71c7348.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "updateProfilePic",
            "endpoint": "https://8peg6bl568.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "updateNickname",
            "endpoint": "https://h5gs185boj.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "gcpServiceList",
            "endpoint": "https://b5ia3jj7v7.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "azureServiceList",
            "endpoint": "https://s39hy6jvkg.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "stripeAPI",
            "endpoint": "https://hlq75ql0t8.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "stripeCustomerPortal",
            "endpoint": "https://4vv1nq9epd.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "getSubQuesTrack",
            "endpoint": "https://1ipgl8pnu0.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "contactForm",
            "endpoint": "https://yae38obi9c.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "submitedit",
            "endpoint": "https://nd14vwooqf.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "the-question-bank213604-master",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "7de9d6d8345b4ef3978772bbedf50f2a",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
