import React from 'react';
import { useHistory} from 'react-router-dom';
import { AmplifyAuthenticator ,AmplifySignIn ,AmplifySignUp } from '@aws-amplify/ui-react';
import './styles/signup.css'
import Container from 'react-bootstrap/Container'

const Signup = () => {


  const history = useHistory();

   function handleAuthStateChange(state){
     console.log(state)
     if (state == 'signedin') {
      history.push("/Userhome");
     }
  }
  return (
      <div className="signup">
      <Container>
      <AmplifyAuthenticator  handleAuthStateChange={handleAuthStateChange} usernameAlias="email">
      <AmplifySignUp
        slot="sign-in"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            label: "Email",
            placeholder: "Email as user name",
            inputProps: { required: true, autocomplete: "username" },
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Password",
            inputProps: { required: true, autocomplete: "new-password" },
          },
        ]} 
      >
         <div slot="secondary-footer-content"></div>
      </AmplifySignUp>
       
    </AmplifyAuthenticator>

     </Container> 
      </div> 
  );
};

export default Signup; 