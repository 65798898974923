import React, { useEffect, useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { withRouter } from 'react-router-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Storage, Auth, API } from 'aws-amplify';
import defaultProfilePic from '../img/profile-silhouette.jpg'
import ImageUpload from './ImageUpload'
import Spinner from 'react-bootstrap/Spinner'
import Toast from 'react-bootstrap/Toast'

import '../styles/profilepage.css'

// to do on this class -> movement through steps and vars
// upload pic to s3 and save to dynamoDB
// update user name needs complely coded

const ProfilePage = () => {

    const [sub, setSub] = useState();
    const [token, setToken] = useState();
    const [profilePic, setProfilePic] = useState();
    const [nickname, setNickname] = useState("");
    const [customer, setCustomer] = useState("");
    const [dateJoined, setDateJoined] = useState("");
    const [changeScreenName, setChangeScreenName] = useState(false);
    const [changeProfilePic, setChangeProfilePic] = useState(false);
    const [file, setFile] = useState()
    const [previewUrl, setPreviewUrl] = useState()
    const [step, setStep] = useState(1)
    const [displayToast, setDisplayToast] = useState(false)
    const [toastHeader, setToastHeader] = useState("")
    const [toastSmallHeader, setToastSmallHeader] = useState("")
    const [toastBody, setToastBody] = useState("")


    useEffect(() => {
        getProfileInfo();
    }, []);

    const imageHander = (file, previewUrl) => {
        setPreviewUrl(previewUrl);
        setFile(file);
    }

    const getProflePic = (profilePic) => {
        Storage.get(profilePic, { expires: 120 }).then(response => {
            setProfilePic(response);
        }).catch(
            error => { console.log(error); }
        );
    }

    const getProfileInfo = () => {
        //Get sub and set state 
        Auth.currentAuthenticatedUser().then(response => {

            //set the const for later 
            setSub(response.attributes.sub);
            setToken(response.signInUserSession.idToken.jwtToken);
            // set api call for user profile return
            const apiName = 'GetProfile';
            const path = '/getProfile';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': response.signInUserSession.idToken.jwtToken
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    sub: response.attributes.sub
                },
            };
            //make api call 
            API
                .get(apiName, path, myInit)
                .then(response => {
                    //set state after render
                    setNickname(response.data.Item.nickname.S)
                    setDateJoined(response.data.Item.createdAt.S)
                    getProflePic(response.data.Item.picture.S);
                })
                .catch(error => {
                    console.log(error);
                });
        })
    }

    const updateProfilePic = () => {
        //api call to update the s3 storage with the profile pic 
        Storage.put("profile-pictures/" + sub + "/profile-pic.png", file, {
            contentType: 'image/png'
        }).then(response => {
            const key = response["key"]
            Auth.currentAuthenticatedUser().then(response => {
                ////and call lambda to update dynamoDB
                const apiName = 'updateProfilePic';
                const path = '/updateProfilePic';
                const myInit = { // OPTIONAL
                    headers: {
                        'Authorization': response.signInUserSession.idToken.jwtToken
                    }, // OPTIONAL
                    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                    queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    }, body: {
                        sub: sub
                        , key: key
                    },
                };
                //make api call 
                API
                    .put(apiName, path, myInit)
                    .then(response => {
                        //update profil here after it's been submitted
                        getProfileInfo()
                        setStep(3)
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }).catch(error => {
                console.log(error)
            })
        }).catch(
            error => { console.log(error); }
        );
    }

    const updateNickame = () => {
        Auth.currentAuthenticatedUser().then(response => {
            const apiName = 'updateNickname';
            const path = '/updateNickname';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': response.signInUserSession.idToken.jwtToken
                },
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                }, body: {
                    nickname: nickname,
                    sub: sub,
                },
            };
            //make api call 
            API.put(apiName, path, myInit)
                .then(response => {
                    //update profil here after it's been submitted
                    if(response.data.isAvailable){
                    getProfileInfo()
                    setStep(3)
                    } else 
                    {
                     setStep(4)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }).catch(error => {
            console.log(error)
        })
    }

    const ScreenName = () => {
        if (nickname === "Complete Profile") {
            return <Button onClick={(e => updateScreenname())}>Set Screen Name</Button>
        }
        return nickname
    }

    const saveNick = () => {
        setStep(step + 1)
        updateNickame();
    }

    const onChangeNick = (e) => {
        setNickname(e.target.value)
    }
    const updateScreenkNameStepone = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>
                    Step 1 of 2
            </Card.Header>
                <Card.Body>
                    <Card.Title>Change Screen Name</Card.Title>
                    <Card.Text>
                        Max Length 20 Characters
                    </Card.Text>
                    <input
                        onChange={e => onChangeNick(e)}
                        value={nickname}
                        type="text"
                        className="nickname"
                        name="nickname"
                        maxLength="20"
                    />
                    <Button onClick={saveNick}>Submit</Button>
                </Card.Body>
            </Card>
        )
    }


    const updateScreenkNameSteptwo = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>
                    Step 2 of 2
            </Card.Header>
                <Card.Body>
                    <Card.Title>Saving</Card.Title>
                    <Card.Text>
                        <Spinner animation="border" />
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }


    const updateScreenkNamethree = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>
                    Complete
            </Card.Header>
                <Card.Body>
                    <Card.Title>Success</Card.Title>
                    <Card.Text>
                        Your Screen Name has been updated.
                    </Card.Text>
                    <Button onClick={done}>Done</Button>
                </Card.Body>
            </Card>
        )
    }

    const updateScreenkNameFour = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>
                    Step 1 of 2
            </Card.Header>
                <Card.Body>
                    <Card.Title>Please Choose Another</Card.Title>
                    <Card.Text>
                    That screen name is already in use. Max Length 20 Characters
                    </Card.Text>
                    <input
                        onChange={e => onChangeNick(e)}
                        value={nickname}
                        type="text"
                        className="nickname"
                        name="nickname"
                        maxLength="20"
                    />
                    <Button onClick={saveNick}>Submit</Button>
                </Card.Body>
            </Card>
        )
    }


    const updateProfilePicStepone = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>
                    Step 1 of 2
            </Card.Header>
                <Card.Body>
                    <Card.Title>Change profile Picture</Card.Title>
                    <Card.Text>
                        <div className="imageUpload"><ImageUpload imageHander={imageHander} /></div>
                    </Card.Text>
                    <Button onClick={save}>Save Image</Button>
                </Card.Body>
            </Card>
        )
    }

    const check_file = () => {
        //type: "video/mp4"
        let passCheck, errorTxt
        //console.log(String(file.type).includes('application/pdf'))
       if (file.size > 500000) {
            passCheck = false
            errorTxt = "File Size must be less than 500KB"
            return { passCheck, errorTxt };
        } else if (file.type!== 'image/jpeg' && file.type!== 'image/png'){
            passCheck = false
            errorTxt = "File Must be PNG or JPEG"
            return { passCheck, errorTxt };
        }else {
            passCheck = true
            errorTxt = "passed Checks"
            return { passCheck, errorTxt };
        }
    }


    const save = () => {
        //check here first if profile pic meets standards
        let check = check_file()
        let passCheck = check.passCheck, errorTxt = check.errorTxt
        if (passCheck) {
            setStep(step + 1)
            updateProfilePic();
        } else {
            //display toast
            setToastSmallHeader("Whoops!")
            setToastBody(errorTxt);
            setDisplayToast(true)
        }
    }




    const updateProfilePicSteptwo = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>
                    Step 2 of 2
            </Card.Header>
                <Card.Body>
                    <Card.Title>Saving</Card.Title>
                    <Card.Text>
                        <Spinner animation="border" />
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }


    const updateProfilePicStepthree = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>
                    Complete
            </Card.Header>
                <Card.Body>
                    <Card.Title>Success</Card.Title>
                    <Card.Text>
                        Your profile picture has been updated.
                    </Card.Text>
                    <Button onClick={done}>Done</Button>
                </Card.Body>
            </Card>
        )
    }

    const done = () => {
        setStep(1);
        setChangeProfilePic(false)
        setChangeScreenName(false)
    }

    const change = () => {
        setChangeProfilePic(true);
    }

    const updateScreenname = () => {
        setChangeScreenName(true);
    }

    const toastforCheck = () => {
        return (
            <Toast style={{
                position: 'absolute',
                top: "10%",
                left: "35%"
            }} show={displayToast} onClose={(e) => setDisplayToast(false)} >
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                    <strong className="mr-auto">{toastHeader}</strong>
                    <small>{toastSmallHeader}</small>
                </Toast.Header>
                <Toast.Body>{toastBody}</Toast.Body>
            </Toast>
        );
    }




    return (
        <div className="container-fluid fill">
            <div className="ProfilePage">
                {!changeScreenName && !changeProfilePic && <div className="main">
                    <Row>
                        <div className="header">
                            <h2>User Profile</h2>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={7}>
                            <div className="ProlfileImage">
                                <Card className="text-center shadow p-3 mb-5 bg-white rounded mx-auto">
                                    <Card.Img className="card-img-top mx-auto" src={profilePic || defaultProfilePic} alt="" />
                                    <Card.Body>
                                        <Button className="btn mx-auto" size="lg" onClick={change}>Change Profile Pic</Button>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="coreCards">
                                <Row>
                             
                                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                            <Card.Body>
                                                <Card.Title>Profile Information</Card.Title>
                                                <Card.Text>
                                                    <div className="screenName">
                                                        Screen Name: {ScreenName()}
                                                    </div>
                                                    <div className="dateJoined">
                                                        Date Joined:  {dateJoined || ""}
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                               
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                }
                {changeProfilePic && <div className="updateProfilePic">
                    <Row>
                        <Col>
                            {step === 1 && updateProfilePicStepone()}
                            {step === 2 && updateProfilePicSteptwo()}
                            {step === 3 && updateProfilePicStepthree()}
                            <div>
                                {displayToast && toastforCheck()}
                            </div>
                        </Col>
                    </Row>
                </div>
                }
                {changeScreenName && <div className="updateScreenName">
                    <Row>
                        <Col>
                            {step === 1 && updateScreenkNameStepone()}
                            {step === 2 && updateScreenkNameSteptwo()}
                            {step === 3 && updateScreenkNamethree()}
                            {step === 4 && updateScreenkNameFour()}
                            <div>
                                {displayToast && toastforCheck()}
                            </div>
                        </Col>
                    </Row>
                </div>
                }
            </div>
        </div>

    )

}

export default withRouter(ProfilePage);