import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Amplify, { Storage, Auth, API } from 'aws-amplify';
import awsconfig from '../aws-exports';
import defaultProfilePic from '../img/profile-silhouette.jpg'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
Amplify.configure(awsconfig);


class Profile extends Component {


    constructor(props) {
        super(props);
        this.state = {
            Name: "",
            ProfilePic: "",
            Sub: null,
            Token: null,
            createdAt: null,
            profilePicURL: null,
            customer: null,

        };
    }



    componentDidMount() {
        //Get sub and set state 
        Auth.currentAuthenticatedUser().then(response => {
            this.setState({ Token: response.signInUserSession.idToken.jwtToken });
            this.setState({ Sub: response.attributes.sub });
        }).then(response => {
            // set api call for user profile return
            const apiName = 'GetProfile';
            const path = '/getProfile';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': this.state.Token
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    sub: this.state.Sub
                },
            };
            //make api call 
            API
                .get(apiName, path, myInit)
                .then(response => {
                    //set state after render
                    this.setState({
                        Name: response.data.Item.nickname.S,
                        createdAt: response.data.Item.createdAt.S,
                        profilePicURL: response.data.Item.picture.S,
                    });

                    Storage.get(response.data.Item.picture.S, { expires: 120 }).then(response => {
                        this.setState({ ProfilePic: response });
                    }).catch(
                        error => { console.log(error); }
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        })
    }


    render() {
        return (
                    <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                        <Card.Img className="card-img-top mx-auto" src={this.state.ProfilePic || defaultProfilePic} alt="" />
                        <Card.Body>
                            <Card.Text>Welcome back {this.state.Name}</Card.Text>
                            <Link to="/Profile">
                            <Button className="btn mx-auto" size="lg">Account Info</Button>
                            </Link>
                        </Card.Body>
                    </Card>
       
        )
    }
}

export default Profile;