import React from 'react'
import { Link } from 'react-router-dom';
import './styles/homepage.css'
import signUpLogo from './img/signUp.png'
import questionTopics from './img/questionTopics.png'
import faq from './img/faq.png'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'


/*
              <p> Please sign up here </p>
                            <Link to="/Userhome">
                                <button type="button">
                                    Sign up
                            </button>
                            </Link>
*/

function HomePage() {

    return (

        <div className="container-fluid">
            <div className="homePage">
                <div className="homePageTop">
                    <div className="homePageHeader">
                        <Row>
                            <Col lg={12}>
                                <h1>The QuestionBank</h1>
                            </Col>
                        </Row>
                    </div>
                    <div className="homePageQoute">
                        <Row>
                            <Col lg={4}></Col>
                            <Col lg={4}>
                                <blockquote class="blockquote">
                                    <i><p class="mb-0">"At The QuestionBank we believe that gainning more knowledge and understanding is fundamental to creating better outcomes."</p></i>
                                </blockquote>
                            </Col>
                            <Col lg={4}></Col>
                        </Row>
                    </div>
                    <div className="HomepageSignUp">
                        <Row>
                            <Col lg={5}></Col>
                            <Col lg={2}>
                                <p> Test your knowledge against 100's of community driven questions</p>
                                <Link to="/Signup">
                                    <button className="btn btn-lg btn-huge mx-auto" type="button">
                                        Sign up
                            </button>
                                </Link>
                            </Col>
                            <Col lg={5}></Col>
                        </Row>
                    </div>
                </div>
                <div className="homepageCards">
                    <Row className="equal">
                        <Col lg={4} className="d-flex pb-3">
                            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                <Card.Img className="card-img-top mx-auto" src={questionTopics} alt="Card image cap" />
                                <Card.Body>
                                    <Card.Title>What Topics Are Currently Availble?</Card.Title>
                                    <Card.Text>Currently the question bank conains 500+ AWS questions</Card.Text>
                                    <Link className="nav-link" to="/Faq">
                                    <Button className ="mx-auto"  size="lg" >Question Topics</Button>
                            </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} className="d-flex pb-3">
                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                <Card.Img className="card-img-top mx-auto" src={signUpLogo} alt="Card image cap" />
                                <Card.Body>
                                    <Card.Title>Sign Up</Card.Title>
                                    <Card.Text>Sign Up to test your knowledge against 1000's of community driven questions</Card.Text>
                                    <Link className="nav-link" to="/Signup">
                                    <Button  className ="mx-auto"  size="lg">Sign Up</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} className="d-flex pb-3">
                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                                <Card.Img className="card-img-top mx-auto" src={faq} alt="Card image cap" />
                                <Card.Body>
                                    <Card.Title>FAQS</Card.Title>
                                    <Card.Text>Take a look at our FAQ's</Card.Text>
                                    <Link className="nav-link" to="/Faq">
                                    <Button className ="mx-auto"  size="lg">FAQS</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </div>
    );
}
export default HomePage;