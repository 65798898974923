import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Amplify, { Storage, Auth, API } from 'aws-amplify';
import awsconfig from '../aws-exports';
import Moment from 'moment';


import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Card from 'react-bootstrap/Card'

import "../styles/results.css"

Amplify.configure(awsconfig);

class Results extends Component {

    home = () => {
        // - you can now access this.props.history for navigation
        this.props.history.push("/");
    };


    //= {this.props.location.state.questions} score = {this.props.location.state.score} totalQuestions = {this.props.location.state.totalQuestions}

    constructor(props) {
        //set state to the results passed through 
        //desplay the resuts whilst making the api call to write
        super(props);
        this.state = {
            questions: this.props.questions,
            score: this.props.score,
            totalQuestions: this.props.totalQuestions,
            startTime: Moment(this.props.startTime).format('YYYY-MM-DD HH:mm:ss'),
            percentageScore: 0,
            sub: null,
            uuid: this.props.uuid,
            saving: true
        }
    }


    //happens after render
    componentDidMount() {

        let sub //, //token
        //Get sub and set state 
        Auth.currentAuthenticatedUser().then(response => {
            //token = response.signInUserSession.idToken.jwtToken;
            sub = response.attributes.sub;

            var i;
            for (i = 0; i < this.state.questions.length; i++) {
                //store call
                Storage.put('quiz/user/' + response.attributes.sub + '/' + this.state.uuid + '/' + i + '.json', this.state.questions[i], {
                    contentType: 'application/json'
                })
                    .catch(err => console.log(err));
            }


        }).then(response => {
            // set api call for user profile return
            const apiName = 'quizResultsDynamo';
            const path = '/quizResults/put';
            const myInit = { // OPTIONAL
                headers: {

                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    sub: sub,
                    quizDate: this.state.startTime,
                    score: this.props.score,
                    uuid: this.props.uuid,
                    complete: "true"
                },
            };
            //make api call 
            API
                .get(apiName, path, myInit)
                .then(response => {
                    //set state after render
                    this.setState({ saving: false })
                    this.props.review(true)

                })
                .catch(error => {
                    console.log(error);
                });

        })
    }

    handleOnCLick() {
        this.props.history.push({
            pathname: '/Userhome',
        });
    }

    render() {
        return (

            <div className="results-sent mx-auto">
                <Row>
                    <Col lg={2} sm={0}></Col>
                    <Col lg={8}></Col>
                    <Card className="text-center shadow p-3 mb-5 bg-white rounded mx-auto">
                        <Card.Header>Saving Quiz</Card.Header>
                        <Card.Body>
                            <Card.Title>Saving your quiz as we speak</Card.Title>
                            <div className="spinner">
                                <Spinner animation="border" />
                            </div>
                        </Card.Body>
                    </Card>
                    {/*
                !this.state.saving &&
                    <div className="saved">
                        <p> Inside the results component!!! score!!!  {this.state.score}   starttime {this.state.startTime}  UUID {this.state.uuid}</p>
                    </div>
                    */
                    }
                    <Col lg={2} sm={0}></Col>
                </Row>
            </div>
        )
    };
}

export default withRouter(Results);