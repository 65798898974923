import React, { useState, useEffect } from 'react';
import MultiSelect from "react-multi-select-component";
import  {Auth, API } from 'aws-amplify';

const SelectServices = ({ servceOptionsSelected, platform, services }) => {

    const [selected, setSelected] = useState( services || []);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        servceOptionsSelected(selected)
    }, [selected]);

    useEffect(() => {
        getOptions();
    }, []);

    const getOptions = () => {

    
            Auth.currentAuthenticatedUser().then(response => {
                let api, urlPath
                if (platform === "AWS") {
                    api = 'awsServiceList'
                    urlPath = '/awsServices'
                } else if (platform === "GCP"){
                    api = 'gcpServiceList'
                    urlPath = '/gcpServiceList'
                } else if(platform === "Azure"){
                    api = 'azureServiceList'
                    urlPath = '/azureServiceList'
                }

                const apiName = api;
                const path = urlPath;
                const myInit = { // OPTIONAL
                    headers: {
                        'Authorization': response.signInUserSession.idToken.jwtToken
                    }, // OPTIONAL
                    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                    queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    },
                };
                API.get(apiName, path, myInit).then(response => {
                    setOptions(response.data)
                }).catch(error => {
                    console.log(error)
                })

            }).catch(error => {
                console.log(error)
            })
    
    }

    return (
        <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy={"Select"}
        />
    )
}
export default SelectServices;
