import React, { useEffect, useState } from 'react';
import { useHistory} from 'react-router-dom';
import { Storage, Auth, API } from 'aws-amplify';
import Moment from 'moment';
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button';

import '../styles/SaveQuiz.css'


const Save = ({ questions, startTime, uuid, currentQuestionIndex }) => {

    const [step, setStep] = useState(1);
    const history = useHistory(); 

    // this sets sub and writes json down to s3 with no print
    useEffect(() => {
        let sub , token
        //Get sub and set state 
        Auth.currentAuthenticatedUser().then(response => {
            token = response.signInUserSession.idToken.jwtToken;
            sub = response.attributes.sub;
            var i;
            for (i = 0; i < questions.length; i++) {
                //store call
                Storage.put('quiz/user/' + response.attributes.sub + '/' + uuid + '/' + i + '.json', questions[i], {
                    contentType: 'application/json'
                })
                    .catch(err => console.log(err));
            }
        }).then(response => {
            // set api call for user profile return
            const apiName = 'quizResultsDynamo';
            const path = '/quizResults/put';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': token
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    sub: sub,
                    quizDate: Moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                    score: 0,
                    uuid: uuid,
                    complete: currentQuestionIndex.toString()
                },
            }
            //make api call 
            API
                .get(apiName, path, myInit)
                .then(response => {
                    //set state after render
                    setStep(2)
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }, []);
    

    const btnHome = () =>{
        history.push("/Userhome")

    }
    const stepOne = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>Saving</Card.Header>
                <Card.Body>
                    <Card.Title>Saving Progress</Card.Title>
                    <Card.Text>You can result your quiz later once saved</Card.Text>
                    <div className="spinner">
                        <Spinner animation="border" />
                    </div>
                </Card.Body>
            </Card>
        )
    }

    const steptwo = () => {
        return(
            <div className="react-quiz-container">
        <Card className="mx-auto text-center shadow p-3 mb-5 bg-white rounded" style={{ width: '60%' }}>
            <Card.Header>Complete</Card.Header>
            <Card.Body>
                <Card.Title>Quiz Saved</Card.Title>
                <Card.Text className="text-center" >Your quiz has been saved. You restart it later.</Card.Text>
            </Card.Body>
            <Button className="btn mx-auto" size="lg" onClick={btnHome}>Home</Button>
        </Card>
        </div>
        )
    }

    return (
        <div className="saveQuiz">
            {step === 1 && stepOne()}
            {step === 2 && steptwo()}
        </div>
    );
};

export default Save;


