import React, { useState } from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { API } from 'aws-amplify';
import './styles/contact.css'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom';
const Contact = () => {

    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")
    const [step, setStep] = useState(1)
    const [warning, setWarning] = useState(false)


    const checkForText = () => {

        if (email.length > 0 && body.length > 0 && subject.length > 0) {
            return true
        }
        return false

    }

    const submitForm = () => {
        let check = checkForText()
        if (check) {
            setStep(2)
            const apiName = 'contactForm';
            const path = '/contactForm';
            const myInit = { // OPTIONAL
                headers: {
                },
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                }, body: {
                    email: email,
                    subject: subject,
                    body: body,
                },
            };
            //make api call 
            API.put(apiName, path, myInit)
                .then(response => {
                    setStep(3)
                })
                .catch(error => {
                    setStep(4)
                });
        }
        else {
            setWarning(true)
        }
    }

    return (
        <div className="container contact mx-auto">
            <div className="contact">
                <div className="contactheader-top">
                    <Row>
                        <Col>
                            <h2>Contact Us</h2>
                        </Col>
                    </Row>
                </div>
                {step === 1 &&
                    <div className="contactbottom">
                        <Row>
                            <Col>
                                <Form>
                                    {warning && <div className="error">
                                        <Form.Label>Email Subject and Message can not be blank</Form.Label>
                                    </div>
                                    }
                                    <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="name@example.com" onChange={(e => setEmail(e.target.value))} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="contactForm.ControlInput2">
                                        <Form.Label>Subject</Form.Label>
                                        <Form.Control type="subject" placeholder="Subject" onChange={(e => setSubject(e.target.value))} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="contactForm.ControlTextarea1">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" rows={10} onChange={(e => setBody(e.target.value))} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 text-center" controlId="contactForm.ControlButton">
                                        <Button className="mx-auto" size="lg" onClick={submitForm}>Submit</Button>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </div>}
                {step === 2 &&
                    <div className="sending">
                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                            <Card.Header>Sending</Card.Header>
                            <Card.Body>
                                <Card.Text>Request being sent</Card.Text>
                                <div className="spinner">
                                    <Spinner animation="border" />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                }
                {step === 3 &&
                    <div className="success">
                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                            <Card.Header>Success</Card.Header>
                            <Card.Body>
                                <Card.Text>Message has been sent</Card.Text>
                                <Link className="text-center" to="/">
                                    <Button className="mx-auto" size="lg">Home</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>

                }
                {step === 4 &&
                    <div className="error">
                        <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                            <Card.Header>Oppies</Card.Header>
                            <Card.Body>
                                <Card.Text>There was a problem sending your messag</Card.Text>
                                <Button className="mx-auto" size="lg" onClick={(e => setStep(1))}>Try again</Button>
                            </Card.Body>
                        </Card>
                    </div>
                }
            </div>
        </div>
    )

}
export default Contact;