import React from 'react';
import SubmitQuestion from './components/SubmitQuestion'
import { withRouter } from 'react-router-dom';

import { withAuthenticator } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import './styles/submitFrame.css'

Amplify.configure(awsconfig);


function SubmitFrame(){
    return(
        <div className="SubmitFrame">
            <SubmitQuestion/> 
        </div>
    ); 
}

export default withRouter(SubmitFrame)