import React, { useState, useCallback, Fragment } from 'react';
import QuizResultFilter from "../quiz/core-components/QuizResultFilter";
import {AmplifyS3Image} from "@aws-amplify/ui-react";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import '../styles/reviewQuizResults.css'

const ReviewQuizResults = ({ questions, startTime, appLocale, score, closeBtn }) => {

  const [filteredValue, setFilteredValue] = useState('all');

  //handle change of filter 
  const handleChange = (event) => {
    setFilteredValue(event.target.value)
  };


  // step 1 - this is the results filter
  const renderResult = () => (

    <div className="results-body mx-auto"  style={{
      display:"flex",
      width:"100%"
    }}>
      <Row  className='mx-auto'  style={{
      width:"100%"
    }}>
        <Col lg={1}></Col>
        <Col lg={10} sm={12}>
          <h2>Quiz Completed</h2>
          <h5>You Scored {score} out of {questions.length}</h5>
          <QuizResultFilter
            filteredValue={filteredValue}
            handleChange={handleChange}
            appLocale={appLocale}
          />
          <div className="clsBtn">
          {closeBtn()}
          </div>
          {renderQuizResultQuestions()}
        </Col>
        <Col lg={1}></Col>
      </Row>
    </div>

  );


  // step 2 - this is to render the results page
  const renderQuizResultQuestions = useCallback(() => {

    let filteredQuestions;

    if (filteredValue !== 'all') {
      filteredQuestions = questions.filter((question) => question.questionResult === filteredValue);
    } else {
      filteredQuestions = questions
    }

    return (filteredQuestions).map((question, index) => {
      return (
        <div className="result-answer-wrapper shadow-sm p-3 mb-5 bg-white rounded mx-auto" key={index + 1}>
          <Card className="text-cent mx-auto">
            <Card.Header className={"cardHeader" + question.questionResult}>{question.questionResult.toUpperCase()}</Card.Header>
            <Card.Body >
              <Card.Title className="text-left" id="question">Question</Card.Title>
              <Card.Text className="question-question">
                {question.question}
              </Card.Text>
              {question.imageURL.length > 1 && <AmplifyS3Image imgKey={question.imageURL} />}
              <Card.Text>
                <div className="result-answer-options">
                  {
                    renderOptions(question)
                  }
                </div>
                <div className="result-answer-header">
                  Correct Answer(s)
              </div>
                <div className="result-answer">
                  {renderAnswerInResult(question)}
                </div>
                <div className="result-services-header">
                  Explaination
                </div>

                {question.explaination}

              </Card.Text>
            </Card.Body>
          </Card>

        </div>



      )
    })
  }, [filteredValue]);


  //step 3 render options 
  const renderOptions = (question) => {
    return (
      Object.keys(question.options).map((key, i) => (
        <div onClick={null} key={question.questionId + '-' + i} className="checkbox">
          <label>
            <input type="checkbox" autoComplete='off' defaultChecked={question.userAnswer[key] || false} id={key} value={question.options[key]} />
            {
              " " + question.options[key]
            }
          </label>
        </div>
      ))
    );
  }

  //step 4 render result 
  const renderAnswerInResult = (question) => {
    return (
      Object.keys(question.answer).map((key, i) => (

        <li>{question.answer[key]}</li>

      ))
    );

  };





  // return method
  return (
    <Fragment>
      {
        renderResult()
      }
    </Fragment>
  );


}

export default ReviewQuizResults;