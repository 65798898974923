import React, { useState } from 'react';
import { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ImageUpload from './ImageUpload'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import PreviewQuestion from './PreviewQuestion'
import Spinner from 'react-bootstrap/Spinner'
import Amplify, { Auth, API } from 'aws-amplify';
import SelectServices from './SelectServices'
import { Link } from 'react-router-dom';

import awsconfig from '../aws-exports';
import '../styles/peerReview.css'

Amplify.configure(awsconfig);


const PeerReview = () => {

    const [review, setReview] = useState(false);
    const [questionIndex, setQuestionIndex] = useState(0)
    const [questionReview, setQuestionReview] = useState([])

    const [maxQuestion] = useState(questionReview.length)
    const [sub, setSub] = useState("")
    const [feedback, setfeedback] = useState("")
    const [showFeedBackCard, setShowfeedBackCard] = useState(false)
    const [showThanks, setShowThanks] = useState(false)
    const [fetch, setfetch] = useState(false)
    const [noQuest, setNoQuest] = useState(false)
    const [edit, setEdit] = useState(false)
    const [previewUrl, setPreviewUrl] = useState("")
    const [file, setFile] = useState("")

    const [options, setOptions] = useState([])
    const [platform, setPlatform] = useState()
    const [level, setLevel] = useState("")
    const [question, setQuestion] = useState("")
    const [explaination, setExplaination] = useState("")

    //if edits are to be made
    /*
    const [peerPlatform ,setPeerPlatform] = useState(questionReview[questionIndex].question)
    const [peerLevel, setPeerLevel] = useState()
    const [peerServceOptionsSelected ,setPeerServceOptionsSelected] = useState()
    const [peerQuestion ,setPeerQuestion] = useState()
    const [peerPreviewUrl,setPeerPreviewUrl] = useState()
    const [peerfile , setPeerFile] = useState()
    const [peerExplaination, setPeerPPeerExplaination] = useState()
    */


    const getQuestions = () => {
        //Get sub and set state 
        Auth.currentAuthenticatedUser().then(response => {
            setSub(response.attributes.sub);
            const apiName = 'getPeerReview';
            const path = '/getPeerReview';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': response.signInUserSession.idToken.jwtToken
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    sub: response.attributes.sub
                },
            };
            API.get(apiName, path, myInit).then(response => {
                if (response.data.questions.length > 0) {
                    setQuestionReview(response.data.questions)
                } else {
                    setNoQuest(true)
                }
                setfetch(false)
            }).catch(error => {
                console.log(error);
                setfetch(false)
            })
        }).then(response => {

        }
        ).catch(error => {
            setfetch(false)
            console.log(error)
        })
    }

    const SubmmitEdit = () => {
        Auth.currentAuthenticatedUser().then(response => {

            const apiName = 'submitedit';
            const path = '/submitedit';
            const myInit = { // OPTIONAL
                headers: {
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                },
                body: {
                    sub: response.attributes.sub
                    , uuid: questionReview[questionIndex].uuid
                    , options: options
                    , platform: platform
                    , level: level
                    , question: question
                    , explaination: explaination
                    , outcome: "A"
                },
            };
            API.put(apiName, path, myInit).then(response => {
                if (questionIndex < questionReview.length - 1) {
                    setQuestionIndex(questionIndex + 1)
                    setEdit(false)
                } else {
                    //set thanks as out of questions
                    setShowThanks(true)
                    setEdit(false)
                }
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            console.log(error)
        })


    }


    /*
        const peerQuestion = () => {
            return (
                <Card>
                    <Card.Header>
                        <h4>Question {questionIndex} of {maxQuestion}</h4>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>{question}</Card.Title>
                        <Card.Text>
                            The next steps will guide you through the peer review process
                    </Card.Text>
                    </Card.Body>
                </Card>
            )
    
        }
        */

    const stateChange = () => {
        setOptions(questionReview[questionIndex].options)
        setPlatform(questionReview[questionIndex].platform)
        setLevel(questionReview[questionIndex].level)
        setQuestion(questionReview[questionIndex].question)
        setExplaination(questionReview[questionIndex].explanation) //questionReview[questionIndex].explanation
        setEdit(true)
    }

    const peerButtons = () => {
        return (
            <ButtonToolbar className="mx-auto">
                <ButtonGroup className="mx-auto">
                    <Button id="reject" value="R" size="lg" onClick={(e) => setShowfeedBackCard(true)} >Reject</Button>
                    <Button id="skip" className="skip" value="S" size="lg" onClick={(e) => subQuestion(e)}>Skip</Button>
                    <Button id="edit" size="lg" onClick={(e) => stateChange()}>Edit</Button>
                    <Button id="accept" value="A" size="lg" onClick={(e) => subQuestion(e)}>Accept</Button>
                </ButtonGroup>
            </ButtonToolbar>
        )
    }

    const subQuestion = (e) => {
        setShowfeedBackCard(false)
        //Get sub and set state 
        Auth.currentAuthenticatedUser().then(response => {
            //api call to update update dynamo and place feedback
            // use e.target.value outcome
            // set api call for user profile return
            const apiName = 'peerReviewPut';
            const path = '/peerReviewPut';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': response.signInUserSession.idToken.jwtToken
                },
                body: {
                    sub: sub,
                    uuid: questionReview[questionIndex].uuid,
                    outcome: e.target.value,
                    feedback: feedback
                },
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                },
            };
            //make api call 
            API
                .put(apiName, path, myInit)
                .then(response => {
                    //update index 
                    if (questionIndex < questionReview.length - 1) {
                        setQuestionIndex(questionIndex + 1)
                        setEdit(false)
                    } else {
                        //set thanks as out of questions
                        setShowThanks(true)
                        setEdit(false)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }).catch(error => {
            console.log(error)
        });

    }

    const feedBackCard = (e) => {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Card.Title>Question Feedback</Card.Title>
                        <Card.Text>Please  Provide feedback to help us improve this question</Card.Text>
                        <div className="form-group">
                            <textarea className="form-control" id="enterQuestionTxt" onChange={(e) => onSiteChangedFeedBack(e)} rows="3"></textarea>
                        </div>
                    </Card.Body>
                    {!fetch && review && !showThanks && showFeedBackCard && feedBackButtons()}
                </Card>
            </div>
        )
    }
    const onSiteChangedFeedBack = (e) => {
        setfeedback(e.target.value)
    }

    const feedBackButtons = () => {
        return (
            <ButtonToolbar className="mx-auto" >
                <ButtonGroup >
                    <Button id="accept" size="lg" value="R" onClick={(e) => subQuestion(e)}>Submit</Button>
                </ButtonGroup>
                <ButtonGroup >
                    <Button id="skip" size="lg" value="Undo" onClick={(e) => setShowfeedBackCard(false)}>Undo</Button>
                </ButtonGroup>
            </ButtonToolbar >
        )
    }
    const thanks = () => {
        return (
            <Card>
                <Card.Header>
                    <h4>Peer Review</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>Peer Review Complete</Card.Title>
                    <Card.Text>
                        Thanks for the help
                        </Card.Text>
                        <Card.Text>
                    <Link to="/Userhome">
                        <Button className="submitBtn" size="lg">Home</Button>
                    </Link>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

    const noQuestions = () => {
        return (
            <Card>
                <Card.Header>
                    <h4>Peer Review</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>No Questions</Card.Title>
                    <Card.Text>
                        Thanks for the help. Presently there are no questions which need reviewed. Check back later to see if more questions are available.
                    </Card.Text>
                    <Link to="/Userhome">
                        <Button className="submitBtn" size="lg">Home</Button>
                    </Link>
                </Card.Body>
            </Card>
        )
    }


    const welcome = () => {
        return (
            <Card>
                <Card.Header>
                    <h4>Peer Review</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>A Big Thank You</Card.Title>
                    <Card.Text>At The Question Bank we aim to have the largest resouce of community led questions. This would not be possible without people like you.
                    <br /><br />
                    The next steps will guide you through the peer review process.
                    </Card.Text>
                    <Button className="questionBtn" size="lg" onClick={(e) => nextBtn(e)}>Let's Review Questions</Button>
                </Card.Body>
            </Card>
        )
    }

    const loading = () => {
        return (
            <Card>
                <Card.Header>
                    <h4>Fecthing Questions</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Text>We are getting questions for peer review</Card.Text>
                    <Spinner animation="border" />
                </Card.Body>
            </Card>
        );
    }


    const nextBtn = (e) => {
        setfetch(true)
        getQuestions()
        if (questionIndex === 0) {
            setReview(true)
        }
    }

    const displayQuestion = () => {
        return (
            PreviewQuestion(questionReview[questionIndex].question, questionReview[questionIndex].services, questionReview[questionIndex].options, questionReview[questionIndex].explanation, questionReview[questionIndex].s3FileURL, !fetch && review && !showThanks && !showFeedBackCard && peerButtons(), questionReview[questionIndex].platform, questionReview[questionIndex].level, true)
        )
    }

    /// this the new bit


    const imageHander = (previewUrl, file) => {
        setPreviewUrl(previewUrl)
        setFile(file)
    }


    const topic = () => {
        return (
            <Fragment>
                <Card.Text>Topic</Card.Text>
                <Form className="quiz-result-filter-form">
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" value={platform} onChange={(e) => setPlatform(e.value)}>
                            <option value="AWS" >AWS</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Fragment>
        )
    }


    const servceOptionsSelected = (services) => {

    }

    const removeClick = (i, e) => {

        let old = [...options];
        old.splice(i, 1)
        setOptions(old)

        // setOptions(opt);
    }

    const handleCheckChange = (i, e) => {
        const { checked } = e.target
        options[i] = { ...options[i], check: checked };
        setOptions(options)
    }

    const handleChange = (i, e) => {
        const { option, value } = e.target;
        options[i] = { ...options[i], option: value };
        setOptions(options);
    }

    const addClick = () => {
        setOptions(options.concat({ option: "", check: false }))
    }



    const QuestionOptions = () => {
        return options.map((el, i) => (
            <div key={i} class="option">
                <div className="divOption">
                    <label id="optionLabel">Option {i + 1}</label>
                </div>
                <div className="form-group">
                    <label id="check"><input className="form-check-input" type="checkbox" name="check" id="checkBoxAnswer" defaultValue={el.check || ''} onChange={(e) => handleCheckChange(i, e)}></input>Answer</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Type in question option" name="option" onChange={(e) => handleChange(i, e)} defaultValue={el.option || ''} rows="3" />
                    <div className="text-right">
                        <Button id="remove" className="btn" size="md" value='remove' onClick={(e) => removeClick(i, e)}>Remove Option {i + 1}</Button>
                    </div>
                </div>
            </div>
        ))
    }

    const questionsInfo = () => {

        return (
            <Fragment>
                <Card.Text>Please Select A Level Of Question</Card.Text>
                <Form className="quiz-result-filter-form">
                    <Form.Group controlId="exampleForm.Level" className="select-box">
                        <Form.Control as="select" value={level} onChange={(e) => setLevel(e.value)}>
                            <option value="Beginner" >Beginner</option>
                            <option value="Intermediate" >Intermediate</option>
                            <option value="Professional">Professional</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
                <Card.Text>Select Sub Topics</Card.Text>
                <Form className="quiz-result-filter-form">
                    <Form.Group controlId="exampleForm.Level">
                        <SelectServices servceOptionsSelected={servceOptionsSelected} platform={platform} services={questionReview[questionIndex].services} />
                    </Form.Group>
                </Form>
                <Card.Text>Please Type A Question Below</Card.Text>
                <div className="form-group">
                    <textarea className="form-control" id="enterQuestionTxt" defaultValue={questionReview[questionIndex].question} onChange={(e) => setQuestion(e.target.value)} rows="3"></textarea>
                </div>
            </Fragment>
        )
    }


    const imagePrev = () => {

        return (
            <Fragment>
                <Card.Text>Add/change diagram? Leave empty to keep as is.</Card.Text>
                <Card.Text><div className="imageUpload"><ImageUpload imageHander={imageHander} /></div></Card.Text>
            </Fragment>
        )
    }


    const explainationText = () => {

        return (
            <Fragment>
                <Card.Text>Explanation</Card.Text>
                <div className="form-group">
                    <textarea className="form-control" id="enterQuestionTxt" defaultValue={questionReview[questionIndex].explanation} onChange={(e) => setExplaination(e.target.value)} rows="3"></textarea>
                </div>
            </Fragment>
        )
    }


    const check = () => {
        SubmmitEdit()
    }


    const editQuestion = () => {
        return (
            <div className="editQuestion mx-auto">
                <Card>
                    <Card.Header>Edit Question</Card.Header>
                    <Card.Body >
                        <Card.Text>
                            <div className="topic mx-auto">
                                {topic()}
                            </div>
                            <div className="questionifno">
                                {questionsInfo()}
                            </div>
                            <div className="options">
                                {QuestionOptions()}
                                <Button id="add" className="add mx-auto" size="lg" onClick={(e) => addClick(e)}>Add Option</Button>
                            </div>
                            <div className='imagePrev'>
                                {imagePrev()}
                            </div>
                            <div className='Explaination'>
                                {explainationText()}
                            </div>
                            <div className="editBtns">
                                <Button id="backEdit" className="add mx-auto" size="lg" onClick={(e) => setEdit(false)}>Back</Button>
                                <Button id="accept" className="add mx-auto" size="lg" onClick={(e) => check(e)}>Submit</Button>
                            </div>
                        </Card.Text>
                    </Card.Body >
                </Card>

            </div>
        )
    }

    /// this is the new bit


    // return method
    return (
        <div className="results-body">
            <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                    {!fetch && !review && !showFeedBackCard && questionIndex === 0 && welcome()}
                    {fetch && loading()}
                    {!fetch && review && !edit && !showThanks && questionReview.length > 0 && displayQuestion()}
                    {!fetch && review && showThanks && thanks()}
                    {!fetch && review && !showThanks && showFeedBackCard && feedBackCard()}
                    {!fetch && review && edit && questionReview.length > 0 && editQuestion()}
                    {!fetch && noQuest && noQuestions()}
                </Col>
                <Col lg={1}></Col>
            </Row>
        </div>
    );

}

export default withRouter(PeerReview);
