
//import Quiz from './components/Quiz'
import Quiz from './quiz/Quiz'
import { withRouter  ,useHistory} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import MultiSelect from "react-multi-select-component";
import Amplify, { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import awsconfig from './aws-exports';
import { v4 as uuidv4 } from 'uuid';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Toast from 'react-bootstrap/Toast'
import Button from 'react-bootstrap/Button'


import './styles/quizFrame.css'

Amplify.configure(awsconfig);


const QuizFrame = ({ previousQuizData }) => {

    const [quizData, setQuizData] = useState({});
    const [start, setStart] = useState(false);
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([]);
    const [platform, setPlatform] = useState([]);
    const [level, setLevel] = useState([]);
    const [numQuestions, setnumQuestions] = useState(5);
    const [uuid] = useState(uuidv4())
    const [step, setStep] = useState(0)
    const [choc, setChoco] = useState(false)
    const [maxNumberSteps] = useState(4)
    const [displayToast, setDisplayToast] = useState(false)
    const [toastHeader, setToastHeader] = useState("")
    const [toastSmallHeader, setToastSmallHeader] = useState("")
    const [toastBody, setToastBody] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getOptions();
    }, []);

    const history = useHistory();

    const platformOptions = [
        { label: "AWS", value: "1" }
    ];

    const levelOptions = [
        { label: "Beginner", value: "1" },
        { label: "Intermediate", value: "2" },
        { label: "Professional", value: "3" }
    ];



    const getOptions = () => {
        Auth.currentAuthenticatedUser().then(response => {
            const apiName = 'awsServiceList';
            const path = '/awsServices';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': response.signInUserSession.idToken.jwtToken
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    name: 'param',
                },
            };
            API.get(apiName, path, myInit).then(response => {
                setOptions(response.data)
            }).catch(error => { console.log(error) });
        })
            .catch(error => {
                console.log(error);
            });
    }



    const getQuizData = async () => {

        let i, x, z, awsService = "", diff = "", platformId = ""

        for (i = 0; i < selected.length; i++) {
            if (i < selected.length - 1) {
                awsService = awsService + selected[i]['value'].toString() + ',';
            } else {
                awsService = awsService + selected[i]['value'].toString()
            }
        }


        for (x = 0; x < level.length; x++) {
            if (x < level.length - 1) {
                diff = diff + level[x]['value'].toString() + ',';
            } else {
                diff = diff + level[x]['value'].toString()
            }
        }

        for (z = 0; z < platform.length; z++) {
            if (z < platform.length - 1) {
                platformId = platformId + platform[z]['value'].toString() + ',';
            } else {
                platformId = platformId + platform[z]['value'].toString()
            }
        }
    
        Auth.currentAuthenticatedUser().then(response => {
            //const apiName = 'getQuestionsLambda';
            //const path = '/getquiz';
            const apiName = 'getQuestions';
            const path = '/getQuestions';
            const myInit = { // OPTIONAL
                headers: {
                    'Authorization': response.signInUserSession.idToken.jwtToken
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    awsService: awsService,
                    diff: diff,
                    platform: platformId,
                    numQuestions: numQuestions,
                },
            };
            API.get(apiName, path, myInit).then(response => {
                setQuizData(response.data);
                setStart(true);
                setLoading(false);
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            console.log(error)
        })
    };


    /**************
     *  Checks   *
     *************/

    const checks = () => {
        if (step === 1) {
            //check that a topic has been selected
            if (platform.length < 1) {
                return false;
            } else {
                return true;
            }
        } else if (step === 2) {
            if (selected.length < 1) {
                return false;
            } else {
                return true;
            }
        } else if (step === 3) {
            if (level.length < 1) {
                return false;
            } else {
                return true;
            }
        } else if (step === 4) {
            //defaulted to 5 quetions
            return true;
        }
    }


    /****************
     * Toast set up *
     ****************/

    const toast = () => {
        return (
            <Toast style={{
                position: 'absolute',
                top: "10%",
                left: "40%",
            }} show={displayToast} onClose={(e) => setDisplayToast(false)}  >
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                    <strong className="mr-auto">{toastHeader}</strong>
                    <small>{toastSmallHeader}</small>
                </Toast.Header>
                <Toast.Body>{toastBody}</Toast.Body>
            </Toast>
        );
    }

    const setToastTextByStep = () => {
        if (step === 1) {
            toastTextConfig("No Topic Selected", "", "Please select a topic for the quiz")
        } else if (step === 2) {
            toastTextConfig("No Sub Topic Selected", "", "At least one sub topic needs selected")
        } else if (step === 3) {
            toastTextConfig("No Difficulty Level Selected", "", "Please select at least one difficulty level")
        }
    }

    const toastTextConfig = (header, smallHeader, body) => {
        setToastHeader(header);
        setToastSmallHeader(smallHeader);
        setToastBody(body);
    }

    /***************
     * quiz Set Up *
     **************/

    const nextButtonRender = () => {
        return (
            <div className="nxtButton">
                { <div className="butonBar">
                    <Button id="nxtBtn" className="nxtBtn mx-auto" size="lg" onClick={(e) => onClickNextStep(e)}>Next</Button>
                </div>}
            </div>
        )
    }

    const chooseQuiz = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded step1">
                <Card.Header className="choose" >Lets Configure A Quiz</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <Button className="btn mx-auto" size="lg" onClick={(e) => quizSetup(e)}>Lets Go!</Button>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

    const quizSetup = (e) => {
        //update choice 
        setChoco(false);
        setStep(step + 1);
    }

    //steps 
    const quizSetUpStepOne = (e) => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>Step {step} of {maxNumberSteps}</Card.Header>
                <Card.Body>
                    <Card.Title>Topic Slection</Card.Title>
                    <Card.Text>Select a topic for the quiz</Card.Text>
                    <div className="multiSlect mx-auto">
                        <MultiSelect
                            options={platformOptions}
                            value={platform}
                            onChange={setPlatform}
                            labelledBy={"Select"}
                        />
                    </div>
                </Card.Body>
                {nextButtonRender()}
            </Card>
        )
    };

    const quizSetUpStepTwo = (e) => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>Step {step} of {maxNumberSteps}</Card.Header>
                <Card.Body>
                    <Card.Title>Sub Topic</Card.Title>
                    <Card.Text>Select at least one sub topic to be included in the quiz</Card.Text>
                    <div className="multiSlect mx-auto">
                        <MultiSelect
                            options={options}
                            value={selected}
                            onChange={setSelected}
                            labelledBy={"Select"}
                        />
                    </div>
                </Card.Body>
                {nextButtonRender()}
            </Card>
        )
    }

    const quizSetUpStepThree = (e) => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>Step {step} of {maxNumberSteps}</Card.Header>
                <Card.Body>
                    <Card.Title>Difficulty Level</Card.Title>
                    <Card.Text>How Difficult should the questions be?</Card.Text>
                    <div className="multiSlect mx-auto">
                        <MultiSelect
                            options={levelOptions}
                            value={level}
                            onChange={setLevel}
                            labelledBy={"Select"}
                        />
                    </div>
                </Card.Body>
                {nextButtonRender()}
            </Card>
        )
    }

    const quizSetUpStepFour = (e) => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>Step {step} of {maxNumberSteps}</Card.Header>
                <Card.Body>
                    <Card.Title>Number of Questions</Card.Title>
                    <Card.Text>How many questions should there be?</Card.Text>
                    <div className="multiSlect mx-auto">
                        <select value={numQuestions} onChange={e => setnumQuestions(e.target.value)}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                </Card.Body>
                {nextButtonRender()}
            </Card>
        )
    }


    const boxOfChoco = (e) => {
        //update choice 
        setChoco(true);
        setPlatform(platformOptions) 
        setStep(step + 1);
    }

    const chocStep = () => {
        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>Step 1 of 1</Card.Header>
                <Card.Body>
                    <Card.Title>Service Selection</Card.Title>
                    <Card.Text>What services should be included?</Card.Text>
                    <div className="multiSlect mx-auto">
                        <MultiSelect
                            options={options}
                            value={selected}
                            onChange={setSelected}
                            labelledBy={"Select"}
                        />
                    </div>
                </Card.Body>
            </Card>
        )
    }

    const onClickNextStep = (e) => {
        if (step < maxNumberSteps) {
            let check = false
            //check 
            check = checks();
            // move onto next step if check passes
            if (check) {

                setStep(step + 1)
            } else {
                setToastTextByStep()
                setDisplayToast(true)
            }
        } else {

            setStep(step + 1);
            setLoading(true)
        }
    }

    const loadQuiz = () => {

        getQuizData();

        return (
            <Card className="text-center shadow p-3 mb-5 bg-white rounded">
                <Card.Header>Loading Quiz</Card.Header>
                <Card.Body>
                    <Card.Title>Creating Quiz</Card.Title>
                    <Card.Text>Your custom quiz is being created as we speak</Card.Text>
                    <div className="spinner">
                        <Spinner animation="border" />
                    </div>
                </Card.Body>
            </Card>
        )
    }

    return (
        <div className="container-fluid fill">
            <div className="QuizFrame">
                
                {!start &&
                    <div className="makeQuiz">
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={8}>
                                {step === 0 && chooseQuiz()}
                                {step === 1 && choc && chocStep()}
                                {step === 1 && !choc && quizSetUpStepOne()}
                                {step === 2 && !choc && quizSetUpStepTwo()}
                                {step === 3 && !choc && quizSetUpStepThree()}
                                {step === 4 && !choc && quizSetUpStepFour()}
                                {loading && !start && step == 5 && loadQuiz()}
                                <div>
                                    {displayToast && toast()}
                                </div>
                            </Col>
                            <Col lg={2}></Col>
                        </Row>
                    </div>
                }
                {start && !loading &&
                    <Quiz quiz={quizData} uuid={uuid} startQuestionIndex={0} />
                }

            </div>
        </div>
    );
}

export default withRouter(QuizFrame)