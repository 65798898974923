import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Amplify, { Storage, Auth, API } from 'aws-amplify';
import awsconfig from '../aws-exports';
import ImageUpload from './ImageUpload';
import uuid from 'react-uuid'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast'
import Spinner from 'react-bootstrap/Spinner'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import PreviewQuestion from './PreviewQuestion'
import SelectServices from './SelectServices'

import '../styles/submitQuestion.css'


Amplify.configure(awsconfig);


class SubmitQuestion extends Component {

    //= {this.props.location.state.questions} score = {this.props.location.state.score} totalQuestions = {this.props.location.state.totalQuestions}

    constructor(props) {
        //set state to the results passed through 
        //desplay the resuts whilst making the api call to write
        super(props);
        this.state = {
            boolQuestion: true,
            platform: "AWS",
            question: "",
            level: "Beginner",
            options: [{ option: "", check: false }],
            answer: "",
            token: "",
            sub: "",
            step: 0,
            maxNumberSteps: 6,
            explanation: "",
            imageURl: "",
            imageFile: "",
            toastHeader: "",
            toastSmallHeader: "",
            toastBody: "",
            displayToast: false,
            complete: false,
            subbing: false,
            selected: [],
        };
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.imageHander = this.imageHander.bind(this);
        this.next = this.nextBtn.bind(this);
        this.subQuestion = this.subQuestion.bind(this)
        this.servceOptionsSelected = this.servceOptionsSelected.bind(this)
    }


    componentDidMount() {

    }

    back() {
        this.setState(
            {
                step: this.state.step - 1
            }
        )
    }

    btnRender() {
        return (
            <ButtonToolbar className="justify-content-between">
                <ButtonGroup aria-label="First group">
                    <Button id="backBtn" className="backbtn mx-auto" size="lg" onClick={() => this.back()} >Previous</Button>
                </ButtonGroup>
                <ButtonGroup aria-label="Second group">
                    <Button id="nxtBtn" className="nxtBtn mx-auto" size="lg" onClick={(e) => this.nextBtn(e)}>Next</Button>
                </ButtonGroup>
            </ButtonToolbar>
        )

    }


    servceOptionsSelected(selected) {
        this.setState({ selected: selected })
    }

    imageHander(previewUrl, file) {
        this.setState({
            imageURl: previewUrl,
            imageFile: file
        })
    }

    handLevelChange(e) {
        this.setState({
            level: e.target.value
        })
    }


    checkOptionForTrueValue() {
        let i
        for (i = 0; i < this.state.options.length; i++) {
            if (this.state.options[i].check === true) {
                return true
            }
        }
        return false
    }

    checkOptionsHaveText() {
        let i
        for (i = 0; i < this.state.options.length; i++) {
            if (this.state.options[i].option.length < 1) {
                return false
            }
        }
        return true
    }

    check() {
        let passCheck, errorTxt
        //step 1 , step 4 
        if (this.state.step === 2) {
            if (this.state.question.length < 1) {
                passCheck = false
                errorTxt = "Question can not be blank"
                return { passCheck, errorTxt };
            } else if (this.state.selected.length === 0 || this.state.selected.length > 10) {
                passCheck = false
                errorTxt = "Between 1 - 10 sub topics need selected"
                return { passCheck, errorTxt };
            } else {
                passCheck = true
                errorTxt = "passed Checks"
                return { passCheck, errorTxt };
            }
        } else if (this.state.step === 3) {
            if (this.state.imageURl.size > 0) {
                if (this.state.imageURl.size > 500000) {
                    passCheck = false
                    errorTxt = "File Size must be less than 500KB"
                    return { passCheck, errorTxt };
                } else if (this.state.imageURl.type !== 'image/jpeg' && this.state.imageURl.type !== 'image/png') {
                    passCheck = false
                    errorTxt = "File Must be PNG or JPEG"
                    return { passCheck, errorTxt };
                } else {
                    passCheck = true
                    errorTxt = "passed Checks"
                    return { passCheck, errorTxt };
                }
            } else {
                passCheck = true
                errorTxt = "passed Checks"
                return { passCheck, errorTxt };
            }
        } else if (this.state.step === 4) {
            if (this.state.options.length < 2) {
                passCheck = false
                errorTxt = "There most be at least two optons"
                return { passCheck, errorTxt };
            } else if (!this.checkOptionForTrueValue()) {
                passCheck = false
                errorTxt = "At least one option must be marked as the answer"
                return { passCheck, errorTxt };
            } else if (!this.checkOptionsHaveText()) {
                passCheck = false
                errorTxt = "options must contain text"
                return { passCheck, errorTxt };
            } else {
                passCheck = true
                errorTxt = "passed Checks"
                return { passCheck, errorTxt };
            }
        } else if (this.state.step === 5) {
            if (this.state.explanation.length < 1) {
                passCheck = false
                errorTxt = "Explanation can not be blank"
                return { passCheck, errorTxt };
            } else {
                passCheck = true
                errorTxt = "passed Checks"
                return { passCheck, errorTxt };
            }
        } else {
            passCheck = true
            errorTxt = "passed Checks"
            return { passCheck, errorTxt };
        }
    }

    onSiteChanged(e) {
        this.setState({
            boolQuestion: e.target.value
        })
    }
    onSiteChangedQuestion(e) {
        this.setState({
            question: e.target.value
        })
    }
    onSiteChangedExplanation(e) {
        this.setState({
            explanation: e.target.value
        })
    }

    addClick() {
        this.setState(prevState => ({
            options: [...prevState.options, { option: "", check: false }]
        }))
    }


    createUI() {
        return this.state.options.map((el, i) => (
            <div key={i} class="option">
                <div className="divOption">
                    <label id="optionLabel">Option {i + 1}</label>
                </div>
                <div className="form-group">
                    <label id="check"><input className="form-check-input" type="checkbox" name="check" id="checkBoxAnswer" value={el.check || ''} onChange={this.handleCheckChange.bind(this, i)}></input>Answer</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Type in question option" name="option" value={el.option || ''} rows="3" onChange={this.handleChange.bind(this, i)} />
                    <div className="text-right">
                        <Button id="remove" className="btn" size="md" value='remove' onClick={this.removeClick.bind(this, i)}>Remove Option {i + 1}</Button>
                    </div>
                </div>
            </div>
        ))
    }

    handleChange(i, e) {
        const { option, value } = e.target;
        let options = [...this.state.options];
        options[i] = { ...options[i], option: value };
        this.setState({ options: options });
    }

    handleCheckChange(i, e) {
        const { checked } = e.target
        let options = [...this.state.options];
        options[i] = { ...options[i], check: checked };
        this.setState({ options: options });
    }

    removeClick(i) {
        let options = [...this.state.options];
        options.splice(i, 1);
        this.setState({ options });
    }

    //async this call 
    subQuestion() {
        this.setState({ subbing: true });

        //Get sub and set state 
        Auth.currentAuthenticatedUser().then(response => {
            this.setState({ token: response.signInUserSession.idToken.jwtToken });
            this.setState({ sub: response.attributes.sub });

        }).then(response => {

            if (this.state.imageURl.size > 0) {
                Storage.put("quiz/uploaded-images/" + uuid() + '.png', this.state.imageURl, {
                    contentType: 'image/png'
                }).then(result => {
                    // set api call for user profile return
                    const apiName = 'submitQuestion';
                    const path = '/submitQuestion';
                    const myInit = { // OPTIONAL
                        headers: {
                        }, // OPTIONAL
                        body: {
                            platform: this.state.platform,
                            question: this.state.question,
                            options: this.state.options,
                            answer: this.state.answer,
                            token: this.state.token,
                            sub: this.state.sub,
                            s3FileURL: result.key,
                            explanation: this.state.explanation,
                            level: this.state.level,
                            services: this.state.selected

                        },
                        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                        queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                        },
                    };

                    //make api call 
                    API
                        .put(apiName, path, myInit)
                        .then(response => {
                            //create json object to send
                            //move to sucess screen 
                            this.setState({
                                complete: true,
                                subbing: true
                            });
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
            } else {
                // set api call for user profile return
                const apiName = 'submitQuestion';
                const path = '/submitQuestion';
                const myInit = { // OPTIONAL
                    headers: {
                    }, // OPTIONAL
                    body: {
                        platform: this.state.platform,
                        question: this.state.question,
                        options: this.state.options,
                        answer: this.state.answer,
                        token: this.state.token,
                        sub: this.state.sub,
                        s3FileURL: "",
                        explanation: this.state.explanation,
                        level: this.state.level,
                        services: this.state.selected

                    },
                    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                    queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    },
                };

                //make api call 
                API
                    .put(apiName, path, myInit)
                    .then(response => {
                        this.setState({
                            complete: true,
                            subbing: true
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
        }).catch(err => console.log(err));
    }



    handlePlatormChange(event) {
        this.setState({ platform: event.target.value });
    }

    /******************
     * Toast for checks
     ******************/
    toastforCheck() {
        return (
            <Toast style={{
                position: 'absolute',
                top: "10%",
                left: "35%"
            }} show={this.state.displayToast} onClose={(e) => this.setState({ displayToast: false })} >
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                    <strong className="mr-auto">{this.state.toastHeader}</strong>
                    <small>{this.state.toastSmallHeader}</small>
                </Toast.Header>
                <Toast.Body>{this.state.toastBody}</Toast.Body>
            </Toast>
        );
    }


    /*******************
     * Question Set up *
     *******************/
    nextBtn() {
        //checks
        let check = this.check()
        let passCheck = check.passCheck, errorTxt = check.errorTxt
        if (passCheck) {
            let step = this.state.step + 1
            this.setState({
                step: step
            })
        } else {
            let header;
            if (this.state.step === 2) {
                header = "Question Error"
            } else if (this.state.step === 4) {
                header = "Option Error"
            } else if (this.state.step === 5) {
                header = "Explanation Error"
            } else {
                header = "Error"
            }
            this.setState(
                {
                    toastHeader: header,
                    toastSmallHeader: "",
                    toastBody: errorTxt,
                    displayToast: true
                }
            )
        }
    }

    /***********************
     * Step through Pattern
     ***********************/

    //Welcome Page for subbing a question
    subQuestionWelcome() {
        return (
            <Card>
                <Card.Header>
                    <h4>Submit Question</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>A Big Thank You</Card.Title>
                    <Card.Text><p>At The Question Bank we aim to have the largest resouce of community led questions. This would not be possible without people like you.
                        <br /><br />
                        The next steps will guide you through the question submission process.</p>
                        <Button id="nxtBtn" className="questionBtn mx-auto" size="lg" onClick={(e) => this.nextBtn(e)}>Add Question</Button>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }


    //step 1 select 
    subQuestionSetUpStepOne() {
        return (
            <Card>
                <Card.Header>
                    <h4>Step {this.state.step + 1} of {this.state.maxNumberSteps}</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>Topic Slection</Card.Title>
                    <Card.Text>Select a topic for the quiz</Card.Text>
                    <Form className="quiz-result-filter-form">
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Control as="select" value={this.state.platform} onChange={this.handlePlatormChange.bind(this)}>
                                <option value="AWS" >AWS</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    <br />
                    <Button id="nxtBtn" className="nxtBtn mx-auto" size="lg" onClick={(e) => this.nextBtn(e)}>Next</Button>
                </Card.Body>
            </Card>
        )
    };

    //step 2 add question
    subQuestionSetUpStepTwo() {
        return (
            <Card>
                <Card.Header>
                    <h4>Step {this.state.step} of {this.state.maxNumberSteps}</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>Add Question</Card.Title>
                    <Card.Text>Please Select A Level Of Question</Card.Text>
                    <Form className="quiz-result-filter-form">
                        <Form.Group controlId="exampleForm.Level">
                            <Form.Control as="select" value={this.state.level} onChange={this.handLevelChange.bind(this)}>
                                <option value="Beginner" >Beginner</option>
                                <option value="Intermediate" >Intermediate</option>
                                <option value="Professional">Professional</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    <Card.Text>Select Sub Topics</Card.Text>
                    <Form className="quiz-result-filter-form">
                        <Form.Group controlId="exampleForm.Level">
                            <SelectServices servceOptionsSelected={this.servceOptionsSelected} platform={this.state.platform} />
                        </Form.Group>
                    </Form>
                    <Card.Text>Please Type A Question Below</Card.Text>
                    <div className="form-group">
                        <textarea className="form-control" id="enterQuestionTxt" onChange={(e) => this.onSiteChangedQuestion(e)} rows="3" value={this.state.question}></textarea>
                    </div>
                    {this.btnRender()}
                </Card.Body>
            </Card>
        )
    };

    //step 3 add diagram
    subQuestionSetUpStepThree() {
        return (
            <Card>
                <Card.Header>
                    <h4>Step {this.state.step} of {this.state.maxNumberSteps}</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>Diagram</Card.Title>
                    <Card.Text>Add a diagram for your question? If not, click next.</Card.Text>
                    <Card.Text><div className="imageUpload"><ImageUpload imageHander={this.imageHander} /></div> </Card.Text>
                    {this.btnRender()}
                </Card.Body>
            </Card>
        )
    };

    //step 4 add options
    subQuestionSetUpStepFour() {

        return (
            <Card>
                <Card.Header>
                    <h4>Step {this.state.step} of {this.state.maxNumberSteps}</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>Answer Options</Card.Title>
                    <Card.Text>Add at least two options for the answer</Card.Text>
                    <Form>
                        <Form.Group>
                            {this.createUI()}
                        </Form.Group>
                    </Form>
                    <Button id="add" className="add mx-auto" size="lg" onClick={this.addClick.bind(this)}>Add Option</Button>
                    {this.btnRender()}
                </Card.Body>

            </Card>
        )
    };

    //step 5 add  explanation
    subQuestionSetUpStepFive() {
        return (
            <Card>
                <Card.Header>
                    <h4>Step {this.state.step} of {this.state.maxNumberSteps}</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Title>Add An Explanation</Card.Title>
                    <Card.Text>Please add an explanation to explain the answer</Card.Text>
                    <div className="form-group">
                        <textarea className="form-control" id="enterQuestionTxt" onChange={(e) => this.onSiteChangedExplanation(e)} defaultValue={this.state.explanation} rows="3"></textarea>
                    </div>
                    {this.btnRender()}
                </Card.Body>
            </Card>
        )
    };

    //step 6 preview
    subQuestionSetUpStepSix() {
        const buttonBar = (
            <ButtonToolbar className="justify-content-between">
                <ButtonGroup aria-label="First group">
                    <Button id="backBtn" className="backbtn mx-auto" size="lg" onClick={() => this.back()} >Previous</Button>
                </ButtonGroup>
                <ButtonGroup aria-label="Second group">
                    <Button id="nxtBtn" className="nxtBtn" size="lg" onClick={(e) => this.subQuestion(e)}>Next</Button>
                </ButtonGroup>
            </ButtonToolbar>
        )
        return (
            PreviewQuestion(this.state.question, this.state.selected, this.state.options, this.state.explanation, this.state.imageFile, buttonBar, this.state.platform, this.state.level)
        )
    };

    //step 7 submit and wait till finsihed
    subQuestionSetUpStepSeven() {
        // card for screen
        return (
            <Card>
                <Card.Header>
                    <h4>Submitting Question</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Text>Your question is currently being submitted</Card.Text>
                    <Spinner animation="border" />
                </Card.Body>
            </Card>
        );
    }

    //step 8 success and home button
    subQuestionSetUpStepEight() {
        return (
            <Card>
                <Card.Header>
                    <h4>Success</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Text>Your Question will now enter Peer review</Card.Text>
                    <Link to="/Userhome"><Button id="nxtBtn" className="hmeBtn" size="lg">Home</Button></Link>
                </Card.Body>
            </Card>
        )
    }



    //render for return 
    //need to add the submit and load screen
    render() {
        return (
            <Row className="subQuestion">
                <Col lg={2}></Col>
                <Col lg={8}>
                    {!this.state.complete && !this.state.subbing && this.state.step === 0 && this.subQuestionWelcome()}
                    {!this.state.complete && !this.state.subbing && this.state.step === 1 && this.subQuestionSetUpStepOne()}
                    {!this.state.complete && !this.state.subbing && this.state.step === 2 && this.subQuestionSetUpStepTwo()}
                    {!this.state.complete && !this.state.subbing && this.state.step === 3 && this.subQuestionSetUpStepThree()}
                    {!this.state.complete && !this.state.subbing && this.state.step === 4 && this.subQuestionSetUpStepFour()}
                    {!this.state.complete && !this.state.subbing && this.state.step === 5 && this.subQuestionSetUpStepFive()}
                    {!this.state.complete && !this.state.subbing && this.state.step === 6 && this.subQuestionSetUpStepSix()}
                    {!this.state.complete && this.state.subbing && this.subQuestionSetUpStepSeven()}
                    {this.state.complete && this.subQuestionSetUpStepEight()}
                    <div>
                        {this.state.displayToast && this.toastforCheck()}
                    </div>
                </Col>
                <Col lg={2}></Col>
            </Row>
        )
    }

}

export default SubmitQuestion;