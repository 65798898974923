import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from './aws-exports';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import PreviewQuestion from './components/PreviewQuestion'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import './styles/trackSubQuestion.css'



Amplify.configure(awsconfig);


// need to sort auth out on api call 

const TrackSubQuestion = () => {


    const [previewQues, setPreviewQues] = useState(false);
    const [questionResponse, setQuestionResponse] = useState({})
    const buttonBar = <Button className="nxtBtn mx-auto" size="lg" onClick={(e)=>setPreviewQues(false)}>Close</Button>

    const [tableData, setTableData] = useState([]);
    const [columns] = useState([
        {
            dataField: 'date'
            , text: 'Date'
            , sort: true
        },
        {
            dataField: 'uuid'
            , text: 'Question ID'
        },
        {
            dataField: 'Preview'
            , formatter: (cell, row) => {
                return <Button onClick={() => preview(row.uuid)}>Preview Question</Button>
            },
            text: 'Preview'
        },
        {
            dataField: 'status'
            , text: 'Status'
        },
        {
            dataField: 'accepted'
            , text: 'Accepted'
        },
        {
            dataField: 'rejected'
            , text: 'Rejected'
        }, {
            dataField: 'total'
            , text: 'Total'
        }
    ]);

    const defaultSorted = [{
        dataField: 'date',
        order: 'desc'
    }];


    useEffect(() => {
        getSubQuestions();
    }, []);



    const getSubQuestions = () => {
        // set api call 
        Auth.currentAuthenticatedUser().then(response => {
            const apiName = 'trackSubQues';
            const path = '/trackSubQues';
            const myInit = { // OPTIONAL
                headers: {
                    'Content-Type': 'application/json'
                }, // OPTIONAL
                queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                    sub: response.attributes.sub
                },
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };
            //make api call 
            API
                .get(apiName, path, myInit)
                .then(response => {
                    createTableData(response.data);
                });
        });
    }


    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 15,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });


    const createTableData = (subQuestionArray) => {
        let i, arrayData = []
        for (i = 0; i < subQuestionArray.length; i++) {
            arrayData.push({
                date: subQuestionArray[i]["sub_datetime"]
                , uuid: subQuestionArray[i]["uuid"]
                , status: subQuestionArray[i]["status"]
                , accepted: subQuestionArray[i]["accepted"]
                , rejected: subQuestionArray[i]["rejected"]
                , total: subQuestionArray[i]["total-ex-skipped"]
            })
        }
        setTableData(arrayData)
    }

    const preview = (uuid) => {
        console.log(uuid)
        const apiName = 'getSubQuesTrack';
        const path = '/getSubQuesTrack';
        const myInit = { // OPTIONAL
            headers: {
               // 'Authorization': this.state.Token
            }, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL need a comment to force a clean build
                uuid:uuid
            },
        };
        //make api call 
        API
            .get(apiName, path, myInit)
            .then(response => {
                console.log(response)
                setQuestionResponse(response)
                setPreviewQues(true)
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (

        <div className="container-fluid fill">
            <div className="track">
                <div className="track top">
                    <Row>
                        <Col>
                            <h2>Track Submitted Question</h2>
                        </Col>
                    </Row>
                </div>
                <div className="track mid mx-auto">
                    <Row>
                        <Col>
                            <p>
                                The table below shows the status of the Questions which you have submitted to The QuestionBank. Congrats if one
                                of your questions has been selected. However, don't be too disheartened if not. It's a tough process. Without people
                                like you this resource wouldn't be as great - so please keep submitting questions no matter what the outcome.
                        </p>
                        </Col>
                    </Row>
                </div>
                {!previewQues &&
                    <div className="track table mx-auto">
                        <Row>
                            <Col>
                                <BootstrapTable bootstrap4 keyField='id' data={tableData} columns={columns} pagination={pagination} bordered={false} defaultSorted={defaultSorted} />
                            </Col>
                        </Row>
                    </div>
                }
                {previewQues &&
                     PreviewQuestion(questionResponse.data.question, questionResponse.data.services, questionResponse.data.options, questionResponse.data.explanation, questionResponse.data.s3FileURL, buttonBar, questionResponse.data.platform, questionResponse.data.level)
                }
            </div >
        </div>
 
    );
}

export default withRouter(TrackSubQuestion);