import React from 'react';
import Results from './components/Results'
import { withRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

//this.props.location.state.detail
/*
state: { 
                questions: this.state.questions, 
                score: this.state.score,
                totalQuestions: this.state.totalQuestions, 
             }
*/
// <Greeting greeting={greeting} />


function ResultsFrame(){
    return(
        <div className="ResultsFrame">
            <Results/> 
        </div>
    ); 
}

export default withRouter(ResultsFrame)