import React from "react";
import Form from 'react-bootstrap/Form'; 

const QuizResultFilter = ({ filteredValue, handleChange, appLocale }) => {
    return (
        <div className="quiz-result-filter">
            <Form className ="quiz-result-filter-form">
                <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label><h5>Filter Results</h5></Form.Label>
                    <Form.Control as="select" value={filteredValue} onChange={handleChange}>
                        <option value="all">{appLocale.resultFilterAll}</option>
                        <option value="correct">{appLocale.resultFilterCorrect}</option>
                        <option value="incorrect">{appLocale.resultFilterIncorrect}</option>
                    </Form.Control>
                </Form.Group>
            </Form>
        </div>
    );
};

export default QuizResultFilter;
