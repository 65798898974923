import React, { Redirect, useState, useEffect, useHistory, useCallback, Fragment } from 'react';
import { Link, Router } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import logo from './img/logo.png'
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Container, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/nav.css'

const Navtop = () => {


  const [signedIn, setSignedIn] = useState(false)

  useEffect(() => {
    isSignedIn();
  }, []);

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
        isSignedIn()
        break;
      case 'signUp':
        break;
      case 'signOut':
        isSignedIn()
        break;
      case 'signIn_failure':
        break;
      case 'configured':
    }
  });


  const isSignedIn = () => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setSignedIn(true);
      })
      .catch(() => {
        setSignedIn(false);
      });
  }

  const signOut = () => {
    Auth.signOut()
      .then(data => {
        isSignedIn()
      })
      .catch(err => console.log(err));
  }


  return (
    <Navbar expand="lg">
      <Nav.Link  className="navbar-brand" href="#"><img src={logo} width="50" height="50" boarder="0"></img></Nav.Link >
      <Navbar.Toggle  aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link >
            {!signedIn &&
              <Link className="nav-link" to="/">Home</Link>
            }
            {
              signedIn && <Link className="nav-link" to="/Userhome">Home</Link>
            }
          </Nav.Link>
          <Form className="d-flex mx-auto">
            <Link to="/">
              {signedIn && <button onClick={signOut} className="btn btn-lg" type="submit">Sign Out</button>}
            </Link>
            {!signedIn && <Link to="/Userhome">
              <button className="btn btn-lg" type="submit">Sign In</button>
            </Link>
            }
          </Form>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default Navtop;

